import React from 'react'

//router
import { Routes, Route, Navigate } from 'react-router'
//layoutpages
import Default from '../layouts/dashboard/default'
import AuthCheck from '../components/authCheck';
//Views
import Index from '../views/index'
import Admin from '../views/dashboard/admin/admin';
import ClientList from '../views/dashboard/app/client-list';
import CampaignList from '../views/dashboard/app/campaign-list';
import Campaign from '../views/dashboard/app/campaign';
import CampaignSearch from '../views/dashboard/app/campaign-search';
import MetaCampaign from '../views/dashboard/app/meta-campaign';
import TradeDeskCampaign from '../views/dashboard/app/campaign-trade-desk';
import Order from '../views/dashboard/app/order';
import Group from '../views/dashboard/app/group';
import MetaGroup from '../views/dashboard/app/meta-group';
import GroupSearch from '../views/dashboard/app/group-search';
import TradeDeskGroup from '../views/dashboard/app/group-trade-desk';
import MetaOrder from '../views/dashboard/app/meta-order';

import OrderList from '../views/dashboard/app/order-list';
import GroupList from '../views/dashboard/app/group-list';
import UserList from '../views/dashboard/app/user-list';

import Client from '../views/dashboard/app/client';
import MetaClient from '../views/dashboard/app/meta-client';
import TradeDeskClient from '../views/dashboard/app/client-trade-desk';
import SavedReports from '../views/dashboard/app/reports-saved';
import CustomReports from '../views/dashboard/app/reports-custom';

import PropertyList from '../views/dashboard/app/property-list';
import Property from '../views/dashboard/app/property';

import CreativeList from '../views/dashboard/app/creative-list';
import Creative from '../views/dashboard/app/creative';

import PageList from '../views/dashboard/app/page-list';
import MetaPage from '../views/dashboard/app/meta-page';

import InputBriefList from '../views/dashboard/app/input-brief-list';
import InputBrief from '../views/dashboard/app/input-brief';

import PrivacyPolicy from '../views/dashboard/extra/privacy-policy';
import TermsofService from '../views/dashboard/extra/terms-of-service';
import SignIn from '../views/dashboard/auth/sign-in'
import SignOut from '../views/dashboard/auth/sign-out'

// errors
import Error404 from '../views/dashboard/errors/error404'
import Error500 from '../views/dashboard/errors/error500'
import Maintenance from '../views/dashboard/errors/maintenance'
import AccountList from '../views/dashboard/app/account-list';
import ClientSearch from '../views/dashboard/app/client-search';
import { AppProvider, useAppContext } from '../context/appContext';
import YTGroup from '../views/dashboard/app/group-youtube';

const IndexRouters = () => {
    const isLoggedIn = AuthCheck();
    const appValue = useAppContext();

    return (
        <>
            <AppProvider value={appValue}>
                <Routes>
                    <Route path="/" element={isLoggedIn ? <Default /> : <Navigate to="/auth/sign-in" />}>
                        <Route path="/" element={<Index />}></Route>
                        <Route path="dashboard" element={<Index />}></Route>
                        <Route path="app">
                            <Route path="clients" element={<ClientList />} />
                            <Route path="client/DV/:clid" element={<Client />} />
                            <Route path="client/meta/:clid" element={<MetaClient />} />
                            <Route path="client/search/:clid" element={<ClientSearch />} />
                            <Route path="client/TTD/:clid" element={<TradeDeskClient />} />
                              
                            {/* campaign */}
                            <Route path="campaigns" element={<CampaignList />} />
                            <Route path="campaign/search/:cid/" element={<CampaignSearch />} />
                            <Route path="campaign/DV/:cid/" element={<Campaign />} />
                            <Route path="campaign/meta/:cid" element={<MetaCampaign />} />
                            <Route path="campaign/TTD/:cid" element={<TradeDeskCampaign />} />
                            {/* order */}
                            <Route path="orders" element={<OrderList />} />
                            <Route path="order/DV/:oid" element={<Order />} />
                            <Route path="order/meta/:oid" element={<MetaOrder />} />

                            {/* Property */}
                            <Route path="properties" element={<PropertyList />} />
                            <Route path="properties/:pid" element={<Property />} />
                            {/* Creative */}
                            <Route path="creatives" element={<CreativeList />} />
                            <Route path="creative/DV/:cid" element={<Creative />} />

                            {/* group */}
                            <Route path="groups" element={<GroupList />} />
                            <Route path="group/search/:gid/" element={<GroupSearch />} />
                            <Route path="group/DV/:gid" element={<Group />} />
                            <Route path="group/YT/:gid" element={<YTGroup />} />
                            <Route path="group/meta/:gid/" element={<MetaGroup />} />
                            <Route path="group/TTD/:gid" element={<TradeDeskGroup />} />

                            {/* input briefs */}
                            <Route path="input-briefs" element={<InputBriefList />} />
                            <Route path="input-brief/:ibid" element={<InputBrief />} />

                            {/* user */}
                            <Route path="user-list" element={<UserList />} />
                            <Route path="account-list" element={<AccountList />} />

                            {/* report */}
                            <Route path="reporting/saved" element={<SavedReports />} />
                            <Route path="reporting/custom" element={<CustomReports />} />

                        </Route>
                        {/* extra */}
                        <Route path="extra/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="extra/terms-of-service" element={<TermsofService />} />
                        {/*admin*/}
                        <Route path="admin/roles" element={<Admin />} />
                    </Route>

                    <Route path="/auth/sign-in" element={<SignIn />} />
                    <Route path="/auth/sign-out" element={<SignOut />} />

                    {/* error */}
                    <Route path="/errors/error404" element={<Error404 />} />
                    <Route path="/errors/error500" element={<Error500 />} />
                    <Route path="/errors/maintenance" element={<Maintenance />} />
                    <Route path="*" element={<Error404 />} />
                </Routes>
            </AppProvider>
        </>
    )
}

export default IndexRouters
