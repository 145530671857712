import React from 'react'
import Select from 'react-select'
import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { useAppContext } from '../context/appContext'

const ConvertStatus = (status) => {
    if (status === "ENTITY_STATUS_ACTIVE") {
        return true
    } else {
        return false
    }
}
const ConvertBudget = (budgetUnit) => {
    if (budgetUnit === "BUDGET_UNIT_CURRENCY") {
        return "$"
    } else {
        return "%"
    }
}

const SetTargeting = (obj, targetArray) => {
    return new Promise((resolve,reject) => {

        if (targetArray.lineItemAssignedTargetingOptions) {
            targetArray.lineItemAssignedTargetingOptions.map((target, i) => {
                let targetOption = target.assignedTargetingOption
                getTargetingType(targetOption, obj)
            })
            resolve()
        } else {
            targetArray.assignedTargetingOptions.map((target,i) => {
                let targetOption = target
                getTargetingType(targetOption, obj)
            })
            resolve()
        }
    }).finally(() =>{
        console.log("Finished Process 1");
        HandleRanges("age",obj)
    })

}
const formatTargeting = (type, value) => {
    let formattedValue;
    switch(type) {
        case "devices":
            formattedValue = TrimString(value,12)
            if(formattedValue === "Computer"){
                formattedValue = "Computer/Desktop"
            }else if (formattedValue === "Connected Tv"){
                formattedValue = "Connected TV"
            }
            break;
        case "gender":
            formattedValue = TrimString(value,7,true)
            break;
        case "outstream":
            formattedValue = TrimString(value,27,true,true)
            break;
        case "digital_content":
            formattedValue = TrimString(value,20)
            if(formattedValue === 'General'){
                formattedValue = "General Audiences"
            }
            break;
        case "exchange":
            formattedValue = TrimString(value,9)
            break;
        case "native":
            formattedValue = TrimString(value,24,true,true)
            break;
        case "onscreen":
            formattedValue = TrimString(value,19)
            break;
        case "parent":
            formattedValue = TrimString(value,16)
            break;
        case "environment":
            formattedValue = TrimString(value,12)
            if(formattedValue === "Web Optimized"){
                formattedValue = "Web"
            }else if (formattedValue === "Web Not Optimized"){
                formattedValue = "Web (Not Optimized For Mobile)"
            }
            break;
        case "sensitive":
            formattedValue = TrimString(value,19)
            if(formattedValue === "Transportation Accidents"){
                formattedValue = "Accidents"
            }else if (formattedValue === "Downloads Sharing"){
                formattedValue = "File Sharing Downloads";
            }else if (formattedValue === "Suggestive"){
                formattedValue = "Suggestive Content"
            }else if (formattedValue === "Sensitive Social Issues"){
                formattedValue = "Social Issues"
            }
            break;
        case "age":
            formattedValue = TrimString(value,10)
            break;
        case "household":
            formattedValue = TrimString(value,17)
            if(formattedValue === "Lower 50 Percent"){
                formattedValue = 1
            }else if(formattedValue === "Top 41 To 50 Percent"){
                formattedValue = 2
            }else if(formattedValue === "Top 31 To 40 Percent"){
                formattedValue = 3
            }else if(formattedValue === "Top 21 To 30 Percent"){
                formattedValue = 4
            }else if (formattedValue === "Top 11 To 20 Percent"){
                formattedValue = 5
            }else if (formattedValue === "Top 10 Percent") {
                formattedValue = 6
            }
            break;
        
    }
    return formattedValue
}
const HandleRanges = (type,obj) => {
    let range = [];
    switch(type){
        case "age":
            if(obj.age_range.temp.length > 0){
                obj.age_range.temp.forEach((item) => {
                    console.log(item)
                    range.push(Number(item.substring(0,2)))
                })
            }
            obj.age_range.value = []
  
            obj.age_range.value.push(Math.min(...range),Math.max(...range))
            break;
        case "household":
            if(obj.household_income.temp.length > 0){

                range = obj.household_income.temp
            }
            obj.household_income.value = []
  
            obj.household_income.value.push(Math.min(...range),Math.max(...range))
            break;
    }
    
}
const getTargetingType = (option, obj) => {
    if (option.deviceTypeDetails) {
        obj.device_type.push(formatTargeting("devices",option.deviceTypeDetails.deviceType))
    } else if (option.genderDetails) {
        obj.gender.push(formatTargeting("gender",option.genderDetails.gender))
    } else if (option.ageRangeDetails) {
        if(option.ageRangeDetails.ageRange === "AGE_RANGE_UNKNOWN"){
            obj.age_range.unknown = true;
        }else {
            obj.age_range.enabled = true;
            obj.age_range.temp.push(formatTargeting("age",option.ageRangeDetails.ageRange))
        }    
    } else if (option.householdIncomeDetails){
        if(option.householdIncomeDetails.householdIncome === "HOUSEHOLD_INCOME_UNKNOWN"){
            obj.household_income.unknown = true;
        }else {
            obj.household_income.enabled = true;
            obj.household_income.temp.push(formatTargeting("household",option.householdIncomeDetails.householdIncome))
        }   
    } else if (option.browserDetails) {
        if(option.browserDetails.negative){
            obj.browsers.exclude = true
        }
        let opt = { label: option.browserDetails.displayName, value: Number(option.browserDetails.targetingOptionId) }
        obj.browsers.opts.push(opt)
    } else if (option.operatingSystemDetails) {
        if(option.operatingSystemDetails.negative){
            obj.os.exclude = true
        }
        let opt = { label: option.operatingSystemDetails.displayName, value: Number(option.operatingSystemDetails.targetingOptionId) }
        obj.os.opts.push(opt)
    }else if (option.carrierAndIspDetails) {
        if(option.carrierAndIspDetails.negative){
            obj.carriers.exclude = true
        }
        let opt = { label: option.carrierAndIspDetails.displayName, value: Number(option.carrierAndIspDetails.targetingOptionId) }
        obj.carriers.opts.push(opt)
    } else if (option.categoryDetails) {
        if(option.categoryDetails.negative){
            obj.categories.exclude = true
        }
        let opt = { label: option.categoryDetails.displayName, value: Number(option.categoryDetails.targetingOptionId) }
        obj.categories.opts.push(opt)
    } else if (option.contentOutstreamPositionDetails) {
        obj.incontentposition.push(formatTargeting("outstream",option.contentOutstreamPositionDetails.contentOutstreamPosition))
    } else if (option.dayAndTimeDetails) {
        let startTime
        if(!option.dayAndTimeDetails.startHour){
            startTime = 0
        }else {
            startTime = option.dayAndTimeDetails.startHour
        }
        let opt = { day: option.dayAndTimeDetails.dayOfWeek, end_time: Number(option.dayAndTimeDetails.endHour), start_time: Number(startTime) }
        obj.day_time.push(opt)
    } else if (option.deviceMakeModelDetails) {
        if(option.deviceMakeModelDetails.negative){
            obj.devices.exclude = true
        }
        let opt = { label: option.deviceMakeModelDetails.displayName, value: Number(option.deviceMakeModelDetails.targetingOptionId) }
        obj.devices.opts.push(opt)
    } else if (option.digitalContentLabelExclusionDetails) {
        obj.content_label.push(formatTargeting("digital_content",option.digitalContentLabelExclusionDetails.excludedContentRatingTier))
    } else if (option.environmentDetails) {
        obj.environment.push(formatTargeting("environment",option.environmentDetails.environment))
    } else if (option.exchangeDetails) {
        let opt = { label: formatTargeting("exchange",option.exchangeDetails.exchange), value: option.exchangeDetails.exchange }
        obj.exchanges.opts.push(opt)

    } else if (option.geoRegionDetails) {
        if(option.geoRegionDetails.negative){
            obj.geos.exclude = true
        }
        let opt = { label: option.geoRegionDetails.displayName, value: Number(option.geoRegionDetails.targetingOptionId) }
        obj.geos.opts.push(opt)

    } else if (option.languageDetails) {
        if(option.languageDetails.negative){
            obj.languages.exclude = true
        }
        let opt = { label: option.languageDetails.displayName, value: Number(option.languageDetails.targetingOptionId) }
        obj.languages.opts.push(opt)

    } else if (option.nativeContentPositionDetails) {
        obj.nativeposition.push(formatTargeting("native",option.nativeContentPositionDetails.contentPosition))
    } else if (option.omidDetails) {

    } else if (option.onScreenPositionDetails) {
        obj.onscreenposition.push(formatTargeting("onscreen",option.onScreenPositionDetails.onScreenPosition))
    } else if (option.parentalStatusDetails) {
        obj.parental_status.push(formatTargeting("parent",option.parentalStatusDetails.parentalStatus))
    } else if (option.sensitiveCategoryExclusionDetails) {

        obj.sensitive_categories.push(formatTargeting("sensitive",option.sensitiveCategoryExclusionDetails.excludedSensitiveCategory))
    } 
}

const removeItemFromArr = (arr, value) => {
    var index = arr.indexOf(value);
    if (index > -1) {
        if (arr.constructor === Array) {
            arr.splice(index, 1);
        } else {
            arr = [];
        }

    }
    return arr;
}

const GlobalSetInitialValues = (obj, payload) => {
    Object.keys(payload).forEach(function (key) {
        obj[key] = payload[key];
    })
    return obj
}

const GlobalSubmitEvent = (e, obj, setObj, url, mode, config) => {
    e.preventDefault();
    // lets delete empty values so they're not included in the create request or else Meta will throw an error
    Object.keys(obj).forEach(key => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '' || obj[key] === 0) {
            delete obj[key];
        }
    });

    const form = e.currentTarget

    if (form.checkValidity() === false) {
        console.log("FAILED", form.reportValidity(), form.validationMessage)
        var list = form.querySelectorAll(':invalid');
        for (var item of list) {
            console.log(item)
        }
        return false
    } else {
        // Add API call here PATCH for update POST for create
        if (mode !== "create") {
            let resp = axios.patch(url, obj, config)
                .then((res) => {
                    if (res.data) {
                        switch (res.data.message) {
                            case "Failure":
                                return { err: res.data.error, result: false }
                            case "Success":
                                return { err: "", result: true }
                            default:
                                return { err: "Unknown Error", result: false }
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        let data = error.response.data
                        return { err: data, result: false }
                    } else {
                        return { err: error, result: false }

                    }
                })
            setObj({ ...obj });
            return resp
        } else {
            let resp = axios.post(url, obj, config)
                .then((res) => {
                    let ctype = res.headers['content-type']
                    if (res.data) {
                        switch (res.data.message) {
                            case "Failure":
                                return { err: res.data.error, result: false }
                            case "Success":
                                return { err: "", result: true, data: res.data }                                                            
                            default:
                                if(ctype == "text/csv") {
                                    fileDownload(res.data,"youtube.csv")
                                    // {<CSVDownload data={res.data} target="_blank"/>}
                                    return {err:"", result: false}
                                }else {

                                    return { err: "Unknown Error", result: false }
                                }
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        let data = error.response.data
                        return { err: data, result: false }
                    } else {
                        return { err: error, result: false }

                    }
                })
            setObj({ ...obj });
            return resp
        }


    }
};

const GlobalChangeEvent = (name, e, obj, setInitialLoad, setObj, index) => {
    if (setInitialLoad) {
        setInitialLoad(false)
    }
    var value;
    //Set Value
    //Determine If Has Target

    if (e.target) {
        //Determine If Checkbox
        //Fetch Value from Obj first then check whether to add it or remove it 
        if (e.target.type == "checkbox") {
            let val = e.target.value
            let curVal
            //get current value - handles nested names
            if (name.indexOf('.') !== -1) {
                let names = name.split(".")
                curVal = obj[names[0]][names[1]]
            } else {
                curVal = obj[name]
            }
            if (e.target.checked) {
                if (curVal && curVal.constructor === Array) {
                    curVal.push(val)
                } else {
                    curVal = true
                }
            } else {
                if (curVal && curVal.constructor === Array) {
                    curVal = removeItemFromArr(curVal, val)
                } else {
                    curVal = false
                }
            }
            value = curVal
        } else if (e.target.type === "number") {
            value = parseInt(e.target.value)
        } else {
            if (e.target.value) {
                value = e.target.value
            } else {
                value = e.target.innerHTML
            }
        }
    } else if (e.length >= 1) {
        const values = []
        for (let i = 0; i < e.length; i++) {
            if (e[i].name === "adlabels") {
                const labels = e[i].label.split(" | ")
                values.push({ id: e[i].value, name: labels[0] })
            } else if (name.includes("opts") || name.includes("creatives")) {
                values.push({ value: e[i].value, label: e[i].label })
                
            } else {
                values.push(e[i].value)
            }
        }
        value = values
    } else {
        value = e.value
    }

    //Check if nested name      
    if (name.indexOf('.') !== -1 && name.indexOf('[') === -1) {
        let names = name.split(".")
        if (names[2] === undefined) {
            obj[names[0]][names[1]] = value
        } else {
            obj[names[0]][names[1]][names[2]] = value
        }
    } else if (name.indexOf('[') !== -1 && name.indexOf('.') !== -1) {
        let names = name.split('[')
        let names2 = name.split('.')
        obj[names[0]][index][names2[1]] = value
    } else {
        obj[name] = value
    }

    setObj({ ...obj });
};
const RemoveSegment = (arr, obj, load, setObj, i, name) => {
    arr.splice(i, 1)
    let e = { value: arr }
    GlobalChangeEvent(name, e, obj, load, setObj, i)
}
const ValueGetter = (obj, name, index) => {
    let returnObj;

    // check if object arr or nested name
    if (name.indexOf('[') !== -1 && name.indexOf('.') !== -1) {
        let names = name.split('[')
        let names2 = name.split('.')
        returnObj = obj[names[0]][index][names2[1]]
    } else if (name.indexOf('.') !== -1 && name.indexOf('[') === -1) {
        let names = name.split(".")
        if (names.length > 2 && obj[names[0]][names[1]]) {
            returnObj = obj[names[0]][names[1]][names[2]]

        } else {
            returnObj = obj[names[0]][names[1]]
        }
    } else {
        returnObj = obj[name]
    }

    return returnObj
}
const GroupBy = (objectArray, ...properties) => {
    return [...Object.values(objectArray.reduce((accumulator, object) => {
        const key = JSON.stringify(properties.map((x) => object[x] || null));

        if (!accumulator[key]) {
            accumulator[key] = [];
        }
        accumulator[key].push(object);
        return accumulator;
    }, {}))];
}

function Status({ value }) {

    return (
        <select name="status" className="selectpicker form-control" data-style="py-0">
            {value === undefined ?
                <>

                    <option disabled selected hidden>Select</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                </>
                : (value ?
                    <>
                        <option value="true" selected>Active</option>
                        <option value="false">Inactive</option>
                    </>
                    :
                    <>
                        <option value="true" >Active</option>
                        <option value="false" selected>Inactive</option>
                    </>
                )}
        </select>

    )

}

function CurrencySymbol({ cur }) {
    const currency = new Map();
    currency.set("ARS", "$");
    currency.set("AUD", "$");
    currency.set("BRL", "R$");
    currency.set("CHF", "CHF");
    currency.set("CLP", "$");
    currency.set("CNY", "¥");
    currency.set("COP", "$");
    currency.set("CZK", "Kč");
    currency.set("DKK", "kr");
    currency.set("EGP", "£");
    currency.set("EUR", "€");
    currency.set("GBP", "£");
    currency.set("HKD", "$");
    currency.set("HUF", "Ft");
    currency.set("IDR", "Rp");
    currency.set("ILS", "₪");
    currency.set("INR", "₹");
    currency.set("JPY", "¥");
    currency.set("KRW", "₩");
    currency.set("MXN", "$");
    currency.set("MYR", "RM");
    currency.set("NGN", "₦");
    currency.set("NOK", "kr");
    currency.set("NZD", "$");
    currency.set("PEN", "S/.");
    currency.set("PLN", "zł");
    currency.set("RON", "lei");
    currency.set("RUB", "₽");
    currency.set("SEK", "kr");
    currency.set("TRY", "₺");
    currency.set("TWD", "NT$");
    currency.set("RUB", "₽");
    currency.set("USD", "$");
    currency.set("ZAR", "R");
    return (
        <span>{currency.get(cur)}</span>
    )

}

function GetCurrency(cur) {
    const currency = new Map();
    currency.set("ARS", "$");
    currency.set("AUD", "$");
    currency.set("BRL", "R$");
    currency.set("CHF", "CHF");
    currency.set("CLP", "$");
    currency.set("CNY", "¥");
    currency.set("COP", "$");
    currency.set("CZK", "Kč");
    currency.set("DKK", "kr");
    currency.set("EGP", "£");
    currency.set("EUR", "€");
    currency.set("GBP", "£");
    currency.set("HKD", "$");
    currency.set("HUF", "Ft");
    currency.set("IDR", "Rp");
    currency.set("ILS", "₪");
    currency.set("INR", "₹");
    currency.set("JPY", "¥");
    currency.set("KRW", "₩");
    currency.set("MXN", "$");
    currency.set("MYR", "RM");
    currency.set("NGN", "₦");
    currency.set("NOK", "kr");
    currency.set("NZD", "$");
    currency.set("PEN", "S/.");
    currency.set("PLN", "zł");
    currency.set("RON", "lei");
    currency.set("RUB", "₽");
    currency.set("SEK", "kr");
    currency.set("TRY", "₺");
    currency.set("TWD", "NT$");
    currency.set("RUB", "₽");
    currency.set("USD", "$");
    currency.set("ZAR", "R");
    return currency.get(cur)

}

function Currency({ value }) {
    const currency = [
        { value: "ARS", label: "Argentine Peso" },
        { value: "AUD", label: "Australian Dollar" },
        { value: "BRL", label: "Brazilian Real" },
        { value: "CAD", label: "Canadian Dollar" },
        { value: "CHF", label: "Swiss Franc" },
        { value: "CLP", label: "Chilean Peso" },
        { value: "CNY", label: "Chinese Yuan" },
        { value: "COP", label: "Colombian Peso" },
        { value: "CZK", label: "Czech Koruna" },
        { value: "DKK", label: "Danish Krone" },
        { value: "EGP", label: "Egyption Pound" },
        { value: "EUR", label: "Euro" },
        { value: "GBP", label: "British Pound" },
        { value: "HKD", label: "Hong Kong Dollar" },
        { value: "HUF", label: "Hungarian Forint" },
        { value: "IDR", label: "Indonesian Rupiah" },
        { value: "ILS", label: "Israeli Shekel" },
        { value: "INR", label: "Indian Rupee" },
        { value: "JPY", label: "Japanese Yen" },
        { value: "KRW", label: "South Korean Won" },
        { value: "MXN", label: "Mexican Pesos" },
        { value: "MYR", label: "Malaysian Ringgit" },
        { value: "NGN", label: "Nigerian Naira" },
        { value: "NOK", label: "Norwegian Krone" },
        { value: "NZD", label: "New Zealand Dollar" },
        { value: "PEN", label: "Peruvian Nuevo Sol" },
        { value: "PLN", label: "Polish Zloty" },
        { value: "RON", label: "New Romanian Leu" },
        { value: "RUB", label: "Russian Ruble" },
        { value: "SEK", label: "Swedish Krona" },
        { value: "TRY", label: "Turkish Lira" },
        { value: "TWD", label: "New Taiwan Dollar" },
        { value: "USD", label: "US Dollar" },
        { value: "ZAR", label: "South African Rand" },
    ];

    return (
        <Select
            name="currency"
            defaultValue={() => {
                for (let i = 0; i < currency.length; i++) {
                    if (currency[i].value === value) {

                        return currency[i]
                    }
                }
            }}
            options={currency}
        />


    )

}

function BuildOptions(data, trim) {
    var options = []
    data.sort(function (a, b) {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    });
    for (let i = 0; i < data.length; i++) {
        let result = data[i].substring(trim, data[i].length);
        result = result.replace(/_/g, " ");

        options.push({ value: data[i], label: TitleCase(result) })
    }


    return options
}

function TrimString(data, trim, changeCase = true, convertDash = false) {

    if (data) {
        let result = data?.substring(trim, data.length);        
        if(convertDash) {            
            result = result.replace("_","-")            
        }else {
            result = result?.replace(/_/g, " ");
        }
        if (changeCase) {
            return TitleCase(result)
        } else {
            return result
        }
    } else {
        return data
    }
}

function FormatNumber(num) {
    let n = num.toLocaleString("en-US")
    return n
}

function DataFilter(filterKey, filterValue, filterOp, filterData, reverse, compareData) {
    let NewData = { data: [] };
    if (reverse) {
        for (let x = 0; x < compareData.length; x++) {

            for (let n = 0; n < filterData.length; n++) {


                if (filterData[n] === compareData[x][filterKey]) {
                    NewData.data.push(compareData[x]);
                }
            }

        }
    } else {

        for (let i = 0; i < filterData.length; i++) {
            if (!filterData[i].hasOwnProperty(filterKey)) {
                console.error("Filter Key Does Not Exist In Dataset");
                return;
            }
            if (filterOp === "=") {
                // console.log(filterData[i][filterKey]);
                if (filterData[i][filterKey] === filterValue) {
                    NewData.data.push(filterData[i]);
                }
            } else if (filterOp === "<") {

                if (filterData[i][filterKey] < filterValue) {
                    NewData.data.push(filterData[i]);
                }
            } else if (filterOp === ">") {

                if (filterData[i][filterKey] > filterValue) {
                    NewData.data.push(filterData[i]);
                }
            } else {

                if (filterData[i][filterKey] === filterValue) {
                    NewData.data.push(filterData[i]);
                }
            }
        }
    }


    return NewData;

}

function Timezones({ value }) {

    const timezones = [
        { value: "America/New_York", label: "US/Eastern" },
        { value: "America/Chicago", label: "US/Central" },
        { value: "America/Denver", label: "US/Mountain" },
        { value: "America/Los_Angeles", label: "US/Pacific" },
        { value: "America/Phoenix", label: "US/Arizona" },
        { value: "America/Anchorage", label: "US/Alaska" },
        { value: "Pacific/Honolulu", label: "US/Hawaii" },
        { value: "America/Puerto_Rico", label: "US/Puerto_Rico" },
        { value: "Europe/Copenhagen", label: "Europe/Berlin" },

    ];
    return (
        <Select
            name="timezones"
            defaultValue={() => {
                for (let i = 0; i < timezones.length; i++) {
                    if (timezones[i].value === value) {
                        return timezones[i]
                    }
                }
            }}
            options={timezones}
        />
    )

}

const ObjEmptyCheck = (value) => {
    return Object.keys(value).length === 0 && value.constructor === Object;
}

// const findByKey = (obj, key) => {
//     const arr = obj;
//     if (arr.length) {
//         const result = arr.filter(el => {
//             return el['key'] === key;
//         });
//         if (result && result.length) {
//             return result[0].value;
//         }
//         else {
//             return '';
//         }
//     }
// }

function MetricFormat({ data }) {
    const { val, setVal } = useState(0)
    const { unit, setUnit } = useState("")
    useEffect(() => {
        if (data > 1000000) {
            setVal(data / 1000000);
            setUnit("M");
        } else if (data > 1000) {
            setVal(data / 1000);
            setUnit("K");
        } else {
            setVal(data);
            setUnit("");
        }
    }, [setUnit, setVal, data])

    return (val, unit)
}

function CountEntities(data) {
    let entityCount = 0
    if (data) {

        for (let i = 0; i < data.length; i++) {
            if (data[i].status === true) {
                entityCount++;
            }
        }
    }
    return entityCount
}

const Micros = 1000000

function DateFormat(year, month, day) {
    if (parseInt(month) < 10) {
        month = "0" + month
    }
    if (parseInt(day) < 10) {
        day = "0" + day
    }

    let date = year + "-" + month + "-" + day
    // for (var i = 0; i < data.data.length; i++) {
    //     // data.data[i].dv_order.updateTime = 
    //     // let test = new Date(data.data[i].dv_order.updateTime);
    //     // console.log(test.toLocaleDateString())
    //     // Date.parse(data.data[i].dv_order.updateTime)
    //     // let test = Date.parse(data.data[i].dv_order.updateTime)   
    //     // console.log(data.data[i].dv_order.updateTime.getDate())
    // }

    return date
}

function SortByString(arr, field1, field2) {
    if (field2) {
        arr.sort(function (a, b) {
            return (a[field1][field2] > b[field1][field2]) ? 1 : ((b[field1][field2] > a[field1][field2]) ? -1 : 0)
        })
    } else {
        arr.sort(function (a, b) {
            return (a[field1] > b[field1]) ? 1 : ((b[field1] > a[field1]) ? -1 : 0)
        })
    }
}

function TitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

function HostingSource(source) {
    let newSource
    switch (source) {
        case "HOSTING_SOURCE_CM":
            newSource = "CM360";
            break;
        case "HOSTING_SOURCE_THIRD_PARTY":
            newSource = "3rd Party";
            break;
        case "HOSTING_SOURCE_HOSTED":
            newSource = "DV360";
            break;
        case "HOSTING_SOURCE_RICH_MEDIA":
            newSource = "CM360 - Studio";
            break;
        default:
            newSource = source
    }
    return newSource
}

function CreativeType(source) {
    let newSource
    switch (source) {
        case "CREATIVE_TYPE_STANDARD":
            newSource = "Display";
            break;
        case "CREATIVE_TYPE_EXPANDABLE":
            newSource = "Expandable";
            break;
        case "CREATIVE_TYPE_VIDEO":
            newSource = "Video";
            break;
        case "CREATIVE_TYPE_NATIVE":
            newSource = "Native";
            break;
        case "CREATIVE_TYPE_TEMPLATED_APP_INSTALL":
            newSource = "Mobile Banner Template"
            break;
        case "CREATIVE_TYPE_NATIVE_SITE_SQUARE":
            newSource = "Native (Square)"
            break;
        case "CREATIVE_TYPE_TEMPLATED_APP_INSTALL_INTERSTITIAL":
            newSource = "Mobile Interstitial"
            break;
        case "CREATIVE_TYPE_LIGHTBOX":
            newSource = "Lightbox"
            break;
        case "CREATIVE_TYPE_NATIVE_APP_INSTALL":
            newSource = "Native (App)"
            break;
        case "CREATIVE_TYPE_NATIVE_APP_INSTALL_SQUARE":
            newSource = "Native (Square App)"
            break;
        case "CREATIVE_TYPE_AUDIO":
            newSource = "Audio"
            break;
        case "CREATIVE_TYPE_PUBLISHER_HOSTED":
            newSource = "Publisher Hosted"
            break;
        case "CREATIVE_TYPE_NATIVE_VIDEO":
            newSource = "Native (Video)"
            break;
        case "CREATIVE_TYPE_TEMPLATED_APP_INSTALL_VIDEO":
            newSource = "Video (App)"
            break;
        default:
            newSource = source
    }
    return newSource
}

function ListItems({ options, data }) {
    var listArr = [];
    for (let i = 0; i < options.length; i++) {
        for (let n = 0; n < data.data.dv_targeting.assignedTargetingOptions.length; n++) {
            if (options[i].value === data.data.dv_targeting.assignedTargetingOptions[n].assignedTargetingOptionIdAlias) {
                listArr.push({ label: options[i].label, type: data.data.dv_targeting.assignedTargetingOptions[n].targetingType });
            }
        }
        const listItems = listArr.map((item) => {
            if (item.type === 'TARGETING_TYPE_DIGITAL_CONTENT_LABEL_EXCLUSION') {
                return (<><li><i className="icon list"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                </svg></i><span>{item.label}</span></li></>);

            } else {
                return (<><li><i className="icon list"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                </svg></i><span>{item.label}</span></li></>);
            }
        }
        );
        return (
            <>{listItems}</>
        )
    }
}

export { GlobalChangeEvent, GlobalSetInitialValues, Status, Currency, Timezones, GetCurrency, DataFilter, TitleCase, ListItems, BuildOptions, MetricFormat, CurrencySymbol, HostingSource, CreativeType, TrimString, FormatNumber, DateFormat, GroupBy, ObjEmptyCheck, ConvertStatus, CountEntities, Micros, SortByString, ValueGetter, GlobalSubmitEvent, ConvertBudget, SetTargeting,HandleRanges };

