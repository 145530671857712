const type_prefix = 'LINE_ITEM_TYPE_'
const status_prefix = 'ENTITY_STATUS_'

const group_info = [
    {
        label: 'Order ID',
        input: 'dropdown',
        name: 'order_id',
        options: [],
        size: '6',
        required: true,
        feedback: 'Order ID is required'
    },
    {
        label: 'Group Name',
        input: 'input-text',
        name: 'name',
        required: true,
        feedback: 'Group name is required',
        size: '6'
    },
    {
        label: 'Type',
        input: 'dropdown',
        name: 'type',
        options: [
            { name: 'type', label: 'Display', value: `${type_prefix}DISPLAY_DEFAULT` },
            { name: 'type', label: 'Video', value: `${type_prefix}VIDEO_DEFAULT` },
            { name: 'type', label: 'Audio', value: `${type_prefix}AUDIO_DEFAULT` },
            { name: 'type', label: 'Video OTT', value: `${type_prefix}VIDEO_OVER_THE_TOP` },
            { name: 'type', label: 'Display App Install', value: `${type_prefix}DISPLAY_MOBILE_APP_INSTALL` },
            { name: 'type', label: 'Video App Install', value: `${type_prefix}VIDEO_MOBILE_APP_INSTALL` },
        ],
        size: '4',
        required: true,
        feedback: 'Type ID is required'
    },
    {
        label: 'Mobile App ID',
        input: 'input-text',
        name: 'mobile_app_id',
        dependent: 'type',
        condition: 'LINE_ITEM_TYPE_DISPLAY_MOBILE_APP_INSTALL' || 'LINE_ITEM_TYPE_VIDEO_MOBILE_APP_INSTALL',
        size: '8'
    },
    {
        label: 'Status',
        input: 'dropdown',
        name: 'status',
        options: [
            { name: 'status', label: 'Active', value: `${status_prefix}ACTIVE` },
            { name: 'status', label: 'Draft', value: `${status_prefix}DRAFT` },
            { name: 'status', label: 'Paused', value: `${status_prefix}PAUSED` }
        ],
        size: '4',
        required: true,
        feedback: 'Status is required'
    },
    {
        label: 'Excludes New Exchanges',
        input: 'toggle',
        name: 'excludes_new_exchanges',
        size: '4'
    }
]


const partner_costs = [
    {
        label: 'Partner Costs',
        input: 'segment',
        name: 'partner_costs',                
        size: '12',
        required: false,
        feedback: 'Partner fee is required'
    },
]

const dateTypePrefix = 'LINE_ITEM_FLIGHT_DATE_TYPE_'

const flight_info = [
    {
        label: 'Flight Date Type',
        input: 'dropdown',
        name: 'flight_date_type',
        options: [
            { label: 'Inherit from Order', value: `${dateTypePrefix}INHERITED` },
            { label: 'Custom', value: `${dateTypePrefix}CUSTOM` }
        ],
        size: '8',
        required: true,
        feedback: 'Flight date type is required'
    },
    {
        label: 'Start Date',
        input: 'date',
        name: 'flight_start_date',
        dependent:"flight_date_type",
        condition:"LINE_ITEM_FLIGHT_DATE_TYPE_CUSTOM",
        size: '6',

    },
    {
        label: 'End Date',
        input: 'date',
        name: 'flight_end_date',
        dependent:"flight_date_type",
        condition:"LINE_ITEM_FLIGHT_DATE_TYPE_CUSTOM",
        size: '6',

    }
]

const alloc_type_prefix = 'LINE_ITEM_BUDGET_ALLOCATION_TYPE_'

const budget_info = [
    {
        label: 'Allocation Type',
        input: 'dropdown',
        name: 'budget.allocation_type',
        options: [
            { name: 'budget_allocation_type', label: 'Automatic', value: `${alloc_type_prefix}AUTOMATIC` },
            { name: 'budget_allocation_type', label: 'Fixed', value: `${alloc_type_prefix}FIXED` },
            { name: 'budget_allocation_type', label: 'Unlimited', value: `${alloc_type_prefix}UNLIMITED` }
        ],
        size: '6',
        required: true,
        feedback: 'Budget allocation type is required'
    },
    {
        label: 'Max Budget',
        name: 'budget.max_amount',
        input: 'input-combo',
        size: "6",
        dependent: 'budget.allocation_type',
        condition: `${alloc_type_prefix}FIXED`,
        options: ["$", "Impressions"],
        addOnName: "budget.unit",
        // required: true,
        // feedback: "Please enter a budget amount",
    },
]

const pacing_period_prefix = 'PACING_PERIOD_'
const pacing_type_prefix = 'PACING_TYPE_'

const pacing_info = [
    {
        label: 'Pacing Period',
        input: 'dropdown',
        name: 'pacing.period',
        options: [
            { name: 'pacing_period', label: 'Daily', value: `${pacing_period_prefix}DAILY` },
            { name: 'pacing_period', label: 'Entire Flight', value: `${pacing_period_prefix}FLIGHT` }
        ],
        size: '6',
        required: true,
        feedback: 'Pacing period is required'
    },
    {
        label: 'Pacing Type',
        input: 'dropdown',
        name: 'pacing.type',
        options: [
            { name: 'pacing_type', label: 'Accelerated Value', value: `${pacing_type_prefix}AHEAD` },
            { name: 'pacing_type', label: 'ASAP', value: `${pacing_type_prefix}ASAP` },
            { name: 'pacing_type', label: 'Even', value: `${pacing_type_prefix}EVEN` }
        ],
        size: '6',
        required: true,
        feedback: 'Pacing type is required'
    },
    {
        label: 'Max Amount',
        name: 'pacing.max_amount',
        input: 'input-combo',
        size: "6",
        dependent: 'pacing.period',
        condition: 'PACING_PERIOD_DAILY',
        options: ["$", "Impressions"],
        addOnName: "pacing.daily_max_type",
        // required: true,
        // feedback: "Please enter a budget amount",
    },
]

const frequency_info = [
    {
        label: "Unlimited",
        input: "toggle",
        name: "frequency_cap.unlimited",
        size: "12"
    },
    {
        label: "Total Allowed Impressions",
        input: "input-number",
        name: "frequency_cap.max_impressions",
        size: "4",
        dependent: "frequency_cap.unlimited",
        condition: false,
    },
    {
        label: "Duration Count",
        input: "input-number",
        name: "frequency_cap.count",
        size: "4",
        dependent: "frequency_cap.unlimited",
        condition: false,
    },
    {
        label: 'Duration Unit',
        input: 'dropdown',
        options: [
            { label: "Lifetime", value: "TIME_UNIT_LIFETIME" },
            { label: "Months", value: "TIME_UNIT_MONTHS" },
            { label: "Weeks", value: "TIME_UNIT_WEEKS" },
            { label: "Days", value: "TIME_UNIT_DAYS" },
            { label: "Hours", value: "TIME_UNIT_HOURS" },
            { label: "Minutes", value: "TIME_UNIT_MINUTES" },
        ],
        name: 'frequency_cap.timeunit',
        dependent: "frequency_cap.unlimited",
        condition: false,
        size: "4",
    },
    

]

const markup_type_prefix = 'PARTNER_REVENUE_MODEL_MARKUP_TYPE_'

const partner_revenue_model_info = [
    {
        label: 'Markup Type',
        input: 'dropdown',
        name: 'partner_revenue_model_markup_type',
        options: [
            { label: '', value: '', name: 'partner_revenue_model_markup_type' },
            { label: 'CPM', value: `${markup_type_prefix}CPM`, name: 'partner_revenue_model_markup_type' },
            { label: 'Media (%)', value: `${markup_type_prefix}TOTAL_MEDIA_COST_MARKUP`, name: 'partner_revenue_model_markup_type' }

        ],
        size: '6',
        required: true,
        feedback: 'Partner revenue markup type is required'
    },
    {
        label: 'Markup Amount',
        input: 'input-number',
        name: 'partner_revenue_model_markup_amount',
        size: '6',
        required: true,
        feedback: 'Partner revenue markup amount is required'
    }
]

const creatives_info = [
    {
        label: 'Enable Creative Optimized Group',
        input: 'toggle',
        name: 'creative_optimization', 
        size: '6',
    },
    {
        label: 'Creatives',
        input: 'async-combo',
        name: 'creatives', 
        url: `/creative/search/`,
        multi: true,
        valueName:"name",
        valueId:"dv_creative_id",
        options: [
        ],
        size: '6',
        // required: true,
        // feedback: 'Creatives are required'
    }
]

const bid_strat_goal_type_prefix = 'BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_'

const bidding_info = [
    // {
    //     label: 'Edit Bidding Info',
    //     input: 'toggle',
    //     name: 'edit_bidding'
    // },
    {
        label: 'Strategy',
        input: 'dropdown',
        name: 'bidding_strategy',
        options: [
            { label: '', value: '', name: 'bidding_strategy' },
            { label: 'Fixed Bid', value: 'Fixed', name: 'bidding_strategy' },
            { label: 'Auto Bid', value: 'Auto', name: 'bidding_strategy' },
            { label: 'Performance Bid', value: 'Performance', name: 'bidding_strategy' }

        ],
        size: '6',
        required: true,
        feedback: 'Bidding strategy is required'
    },
    {
        label: 'Bid Amount',
        input: 'input-text',
        name: 'bid_amount',
        size: '6',
        required: true,
        feedback: 'Bid amount is required'
    },
    {
        label: 'Performance Goal Type (Auto Bid Only)',
        input: 'dropdown',
        name: 'auto_bid_performance_goal_type',
        options: [
            { label: '', value: '' },
            { label: 'CPA', value: `${bid_strat_goal_type_prefix}CPA`, name: 'auto_bid_performance_goal_type' },
            { label: 'CPC', value: `${bid_strat_goal_type_prefix}CPC`, name: 'auto_bid_performance_goal_type' },
            { label: 'vCPM', value: `${bid_strat_goal_type_prefix}VIEWABLE_CPM`, name: 'auto_bid_performance_goal_type' },
            { label: 'Custom', value: `${bid_strat_goal_type_prefix}CUSTOM_ALGO`, name: 'auto_bid_performance_goal_type' },
            { label: 'CIVA', value: `${bid_strat_goal_type_prefix}CIVA`, name: 'auto_bid_performance_goal_type' },
            { label: 'IVO Ten', value: `${bid_strat_goal_type_prefix}IVO_TEN`, name: 'auto_bid_performance_goal_type' },
            { label: 'AV Viewed', value: `${bid_strat_goal_type_prefix}AV_VIEWED`, name: 'auto_bid_performance_goal_type' }
        ],
        dependent: 'bidding_strategy',
        condition: 'Auto',
        size: '6',
    },
    {
        label: "Max Average CPM",
        input: "input-number",
        name: 'auto_bid_performance_goal_cpm',
        dependent: 'bidding_strategy',
        condition: 'Performance',
        size:'6'
    },
    {
        label: "Performance Goal Amount",
        input: "input-number",
        name: 'auto_bid_performance_goal_amount',
        dependent: 'bidding_strategy',
        condition: 'Performance',
        size:'6'

    }
]

const GroupForm = {
    titles: ["General", "Partner", "Flight", "Budget", "Pacing", "Frequency", "Partner Revenue", "Creatives", "Bidding"],
    fields: [
        group_info,
        partner_costs,
        flight_info,
        budget_info,
        pacing_info,
        frequency_info,
        partner_revenue_model_info,
        creatives_info,
        bidding_info
    ]
}

export { GroupForm }