import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, OverlayTrigger, Popover, Card, Container, Stack, Badge, Overlay } from 'react-bootstrap'
import { useAppContext } from '../context/appContext';


//circular
import ReactTable from '../components/table'

// import MaterialTable from "material-table";
// import { ThemeProvider, createTheme } from '@mui/material';
// AOS
import AOS from 'aos'
import 'aos'
import '../../node_modules/aos/dist/aos.css'
//apexcharts
import Chart from "react-apexcharts";

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'

//progressbar
import Progress from '../components/progress.js'

//Count-up
import CountUp from 'react-countup';
// store

import { CurrencySymbol, FormatNumber } from '../components/common'
import meta from '../assets/images/meta-logo.png'
import dv from '../assets/images/dv360-logo.png'
import td from '../assets/images/trade-desk-logo.png'
import search from '../assets/images/sa360-logo.png'
import yt from '../assets/images/yt-logo.png'
import { RetrievePreview } from './ajx.js'
import axios from 'axios';
import Loader from './Loader.js';

const determineLogo = (platform) => {
    // console.log(platform)
    let logo = dv;
    if (platform === "Meta") {
        logo = meta;
    }
    if (platform === "TTD") {
        logo = td;
    }
    if (platform === "Search") {
        logo = search;
    }
    if (platform === "YT") {
        logo = yt
    }
    // console.log(logo)
    return logo
}


// install Swiper modules
SwiperCore.use([Navigation]);


const Dash = (props) => {

    const [preview, setPreview] = useState();
    const target = useRef(null);
    const appValue = useAppContext();

    let dash = props.dash
    let account = props.account
    var emptyModel = {
        image: "",
        url: "",
        title: "",
        description: "",
        type: "",
        keywords: [],
        language: "",
        app: ""
    }
    useEffect(() => {
        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    },)
    let colorArr = ["#3a57e8", "#4bc7d2", "#1AA053", "#F16A1B", "#660add", "#4c1a1a", "#ffb300", "#00916a", "#641a50", "#3e3e3e", "#0c004a", "#780239", "#5e3b16"]


    let kpi = dash.kpi
    var imp;
    var unit;
    var click;
    var cunit;
    var conv;
    var vunit;
    var aimp;
    var aunit;
    var lpv;
    if (kpi.conversions) {
        if (kpi.lpv) {
            kpi.conversions = kpi.conversions + kpi.lpv
        }
    } else {
        kpi.conversions = kpi.lpv
    }

    if (kpi.impressions > 1000000) {
        imp = kpi.impressions / 1000000;
        unit = "M";
    } else {
        imp = kpi.impressions / 1000;
        unit = "K";
    }
    if (kpi.clicks > 1000000) {
        click = kpi.clicks / 1000000;
        cunit = "M";
    } else if (kpi.clicks > 10000) {
        click = kpi.clicks / 1000;
        cunit = "K";
    } else {
        click = kpi.clicks
        cunit = "";
    }
    if (kpi.conversions > 1000000) {
        conv = kpi.conversions / 1000000;
        vunit = "M";
    } else if (kpi.conversions > 10000) {
        conv = kpi.conversions / 1000;
        vunit = "K";
    } else {
        conv = kpi.conversions;
        vunit = "";
    }
    if (dash.anon.impressions > 1000000) {
        aimp = dash.anon.impressions / 1000000;
        aunit = "M";
    } else if (dash.anon.impressions > 10000) {
        aimp = dash.anon.impressions / 1000;
        aunit = "K";
    } else {
        aimp = dash.anon.impressions;
        aunit = "";
    }


    let ctr = (kpi.clicks / kpi.impressions) * 100
    let cpa = kpi.cost / kpi.conversions
    // console.log(kpi.clicks,kpi.impressions,ctr)
    let cpm = kpi.cost / (kpi.impressions / 1000);
    let cpc = kpi.cost / kpi.clicks;
    var regions = [];
    var rclicks = [];
    var rconv = [];
    var rimp = [];
    var rcost = [];

    if (dash.region) {

        Object.keys(dash.region).forEach(function (val, key) {
            regions.push(val);
            rclicks.push(dash.region[val].clicks);
            rconv.push(dash.region[val].conversions);
            rimp.push(dash.region[val].impressions);
            rcost.push((dash.region[val].cost));

        });

    }
    var popover = (
                <div>
                <Row>
                    <Col lg="12">
                        <Card className="bg-dark text-white md-image" >
                            <Card.Img variant="top" src={(preview && preview.image) ? (preview.image) : ("")} />
                            <Card.Body>
                                <Card.Title><a href={(preview && preview.url) ? (preview.url) : ("")} target="_blank">{(preview && preview.title) ? (preview.title) : ("Loading...")}</a></Card.Title>
                                <Card.Subtitle>{(preview && preview.type) ? (preview.type) : ("Unknown")}</Card.Subtitle>
                                <Row className="mt-2">
                                    <Col lg="12">
                                        <strong>Description:</strong> {(preview && preview.description) ? (preview.description) : ("No Description")}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <strong>Language:</strong> {(preview && preview.language) ? (preview.language) : ("Unknown")}
                                    </Col>

                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-muted bg-secondary">
                                <Stack direction="horizontal" gap={2}>
                                    <Row>
                                    <Col lg="12">
                                    {(preview && preview.keywords) ? (
                                        
                                        preview.keywords.map(function (topic, i) {
                                            
                                            return (<Badge pill bg="dark">{topic}</Badge>
                                        )
                                    })
                                ) : (<></>)}
                                </Col>
                                    </Row>
                                </Stack>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
       
        

    const chart1 = {
        options: {
            chart: {
                fontFamily: '"Inter", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                toolbar: {
                    show: false
                },
                sparkline: {
                    enabled: false,
                },

            },

            colors: ["#4bc7d2", "#3a57e8", "#F16A1B"],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            yaxis: {
                show: true,
                labels: {
                    show: true,
                    minWidth: 19,
                    maxWidth: 75,
                    style: {
                        colors: "#8A92A6",
                    },
                    offsetX: -5,
                },
            },
            legend: {
                show: false,
            },
            xaxis: {
                labels: {
                    minHeight: 22,
                    maxHeight: 100,
                    show: true,
                    style: {
                        colors: "#8A92A6",
                    },
                },
                lines: {
                    show: false  //or just here to disable only x axis grids
                },
                categories: regions
            },
            grid: {
                show: false,
            },

            tooltip: {
                enabled: true,
            },
        },
        series: [
            {
                name: 'cost',
                data: rcost
            },
            {
                name: 'clicks',
                data: rclicks
            },
            {
                name: 'conversions',
                data: rconv
            },
        ]

    }


    // const chart2 = {
    //     options: {
    //         colors: ["#3a57e8", "#4bc7d2", "#1AA053", "#F16A1B", "#660add"],
    //         plotOptions: {
    //             pie: {
    //                 donut: {
    //                     labels: {
    //                         show: false,
    //                     }
    //                 },
    //                 dataLabels: false
    //             }
    //         },
    //         labels: ['Connected TV', 'Desktop', 'Smart Phone', 'Tablet', 'DOOH'],
    //     },
    //     series: [(dash.channels["Connected TV"]) ? dash.channels["Connected TV"].cost : 0, (dash.channels["Desktop"]) ? dash.channels["Desktop"].cost : 0, (dash.channels["Smart Phone"]) ? dash.channels["Smart Phone"].cost : 0, (dash.channels["Tablet"]) ? dash.channels["Tablet"].cost : 0, (dash.channels["OutOfHome"]) ? dash.channels["OutOfHome"].cost : 0],
    // }

    const buildPieGraph = (series, labels, colors) => {
        let options = {
            options: {
                colors: colors,
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: false,
                            }
                        },
                        dataLabels: false
                    }
                },
                labels: labels,
            },
            series: series,
        }
        return options
    }

    let channelSeries = []
    let channelLabels = []
    let channelColors = []
    Object.keys(dash.channels).map((name, i) => {

        channelLabels.push(name)
        if (dash.channels[name].cost) {

            channelSeries.push(dash.channels[name].cost)
        } else {
            channelSeries.push(0)
        }
    })
    for (let i = 0; i < channelSeries.length; i++) {
        channelColors.push(colorArr[i])
    }
    const chart2 = buildPieGraph(channelSeries, channelLabels, channelColors)


    var browser;
    if (dash.browsers) {

        let browserSeries = []
        let browserLabels = []
        let browserColors = []
        Object.keys(dash.browsers).map((name, i) => {
            browserLabels.push(name)
            browserSeries.push(dash.browsers[name].cost)
        })
        for (let i = 0; i < browserSeries.length; i++) {
            browserColors.push(colorArr[i])
        }
        browser = buildPieGraph(browserSeries, browserLabels, browserColors)
    }
    var os;
    if (dash.os) {

        let osSeries = []
        let osLabels = []
        let osColors = []
        Object.keys(dash.os).map((name, i) => {

            osLabels.push(name)
            osSeries.push(dash.os[name].cost)
        })
        for (let i = 0; i < osSeries.length; i++) {
            osColors.push(colorArr[i])
        }
        os = buildPieGraph(osSeries, osLabels, osColors)
    }
    var media;
    if (dash.media) {

        let mediaSeries = []
        let mediaLabels = []
        let mediaColors = []
        Object.keys(dash.media).map((name, i) => {

            mediaLabels.push(name)
            mediaSeries.push(dash.media[name].cost)
        })
        for (let i = 0; i < mediaSeries.length; i++) {
            mediaColors.push(colorArr[i])
        }
        media = buildPieGraph(mediaSeries, mediaLabels, mediaColors)
    }
    var age;
    if (dash.age) {

        let ageSeries = []
        let ageLabels = []
        let ageColors = []
        Object.keys(dash.age).map((name, i) => {

            ageLabels.push(name)
            ageSeries.push(dash.age[name].cost)
        })
        for (let i = 0; i < ageSeries.length; i++) {
            ageColors.push(colorArr[i])
        }
        age = buildPieGraph(ageSeries, ageLabels, ageColors)
    }
    var gender;
    if (dash.gender) {

        let genderSeries = []
        let genderLabels = []
        let genderColors = []
        Object.keys(dash.gender).map((name, i) => {

            genderLabels.push(name)
            genderSeries.push(dash.gender[name].cost)
        })
        for (let i = 0; i < genderSeries.length; i++) {
            genderColors.push(colorArr[i])
        }
        gender = buildPieGraph(genderSeries, genderLabels, genderColors)
    }
    const chart3 = {
        options: {
            colors: ["#3a57e8", "#4bc7d2"],
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 10,
                        size: "50%",
                    },
                    track: {
                        margin: 10,
                        strokeWidth: '50%',
                    },
                    dataLabels: {
                        total: {
                            show: true,
                            label: 'Total'
                        }
                    }
                }
            },
            labels: ['Impressions', 'Cost'],
        },
        series: [Math.round((dash.anon.impressions / kpi.impressions) * 100), Math.round((dash.anon.cost / kpi.cost) * 100)],
    }
    var deletedImp = (dash.deleted) ? dash.deleted.impressions : 1
    var deletedTV = (dash.deleted) ? dash.deleted.trueview : 1
    var dashTotalImp = (dash.totals) ? dash.totals.totals.impressions : 1
    var dashTotalTV = (dash.totals) ? dash.totals.totals.trueview : 1

    const chart4 = {
        options: {
            colors: ["#3a57e8", "#4bc7d2"],
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 10,
                        size: "50%",
                    },
                    track: {
                        margin: 10,
                        strokeWidth: '50%',
                    },
                    dataLabels: {
                        total: {
                            show: true,
                            label: 'Total'
                        }
                    }
                }
            },
            labels: ['Impressions', 'Videos'],
        },
        series: [Math.round((deletedImp / dashTotalImp) * 100), Math.round((deletedTV / dashTotalTV) * 100)],
    }

    // const chart4 = {
    //     options: {
    //         colors: ["#3a57e8", "#4bc7d2", "#1AA053", "#F16A1B"],
    //         plotOptions: {
    //             pie: {
    //                 donut: {
    //                     labels: {
    //                         show: false,
    //                     }
    //                 },
    //                 dataLabels: false
    //             }
    //         },
    //         labels: ['18-24', '18-49', '21+', '21-34', '21-44', '21-49', '21-54', '21-64', '25-34', '25-49', '35-44', '35-49', '45-54', '55-64', '65+', 'Unknown'],
    //     },
    //     series: [(dash.age["18-24"]) ? dash.age["18-24"].impressions : 0, (dash.age["18-49"]) ? dash.age["18-49"].impressions : 0, (dash.age["21+"]) ? dash.age["21+"].impressions : 0, (dash.age["21-34"]) ? dash.age["21-34"].impressions : 0, (dash.age["21-44"]) ? dash.age["21-44"].impressions : 0, (dash.age["21-44"]) ? dash.age["21-44"].impressions : 0, (dash.age["21-49"]) ? dash.age["21-49"].impressions : 0, (dash.age["21-54"]) ? dash.age["21-54"].impressions : 0, (dash.age["21-64"]) ? dash.age["21-64"].impressions : 0, (dash.age["25-34"]) ? dash.age["25-34"].impressions : 0, (dash.age["25-49"]) ? dash.age["25-49"].impressions : 0, (dash.age["35-44"]) ? dash.age["35-44"].impressions : 0, (dash.age["35-49"]) ? dash.age["35-49"].impressions : 0, (dash.age["45-54"]) ? dash.age["45-54"].impressions : 0, (dash.age["55-64"]) ? dash.age["55-64"].impressions : 0, (dash.age["65+"]) ? dash.age["65+"].impressions : 0, (dash.age["Unknown"]) ? dash.age["Unknown"].impressions : 0],
    // }
    // const chart5 = {
    //     options: {
    //         colors: ["#3a57e8", "#4bc7d2"],
    //         plotOptions: {
    //             radialBar: {
    //                 hollow: {
    //                     margin: 10,
    //                     size: "50%",
    //                 },
    //                 track: {
    //                     margin: 10,
    //                     strokeWidth: '50%',
    //                 },
    //                 dataLabels: {
    //                     total: {
    //                         show: true,
    //                         label: 'Total'
    //                     }
    //                 }
    //             }
    //         },
    //         labels: ['Male', 'Female', 'Unknown'],
    //     },
    //     series: [Math.round((dash.gender["Male"].impressions / kpi.impressions) * 100), Math.round((dash.gender["Female"].impressions / kpi.impressions) * 100), Math.round((dash.gender["Unknown"].impressions / kpi.impressions) * 100)],
    // }
    // const columns = [
    //     {
    //         field: 'exchange', headerName: 'Exchange', flex: 1, sortable: true, renderCell: (params) => params.row.source,
    //     },
    //     { field: 'channel', headerName: 'Channel', flex: 1, sortable: true, renderCell: (params) => params.row.device_type },
    //     { field: 'order', headerName: 'Order', flex: 1, sortable: true, renderCell: (params) => params.row.oid },
    //     { field: 'impressions', headerName: 'Impressions', flex: 1, sortable: true, renderCell: (params) => params.row.kpi.impressions },
    //     { field: 'clicks', headerName: 'Clicks', flex: 1, sortable: true, renderCell: (params) => params.row.kpi.clicks },
    //     { field: 'conversions', headerName: 'Conversions', flex: 1, sortable: true, renderCell: (params) => params.row.kpi.conversions },
    //     { field: 'cost', headerName: 'Cost', flex: 1, sortable: true, renderCell: (params) => params.row.kpi.cost },
    // ];            
    const webPreview = (website) => {
        setPreview()
        var url = appValue.apiUrl + "/util/preview/" + website
        // const [data, setData] = useState('');
        axios
            .get(url, appValue.config)
            .then((response) => { setPreview(response.data) })
            .catch((error) => {
                console.log(error);
            })

        //   return data
    };
    // useEffect(webPreview,[])
    const buildExchanges = (data) => {
        let keys = Object.keys(data)
        if (data.none && keys.length == 1) {
            return data.none
        } else {
            // let imp;
            // let conv;
            // let cost;
            // let clicks;
            var newObj = { impressions: 0, conversions: 0, cost: 0.0, clicks: 0 }
            Object.keys(data).map((name, i) => {

                newObj.impressions += (data[name].impressions) ? data[name].impressions : 0
                newObj.conversions += (data[name].conversions) ? data[name].conversions : 0
                newObj.cost += (data[name].cost) ? data[name].cost : 0
                newObj.clicks += (data[name].clicks) ? data[name].clicks : 0
            })
            return newObj
        }

    }
    const subExchangeExists = (data) => {
        let result = Object.keys(dash.exchanges).map((name, i) => {
            let keys = Object.keys(dash.exchanges[name])
            if (keys.length > 1) {
                return true;
            }
        });
        if (result) {
            return true;
        } else {
            return false;
        }
    }
    dash.campaign = { ...dash.campaign, ...dash.ttd_campaign }
    dash.order = { ...dash.order, ...dash.meta_order }
    dash.group = { ...dash.group, ...dash.ttd_group }

    return (

        <Row>
            <Col md="12" lg="12">
                <Row className="row-cols-1">
                    <div className="overflow-hidden d-slider1 ">
                        <Swiper className="p-0 m-0 mb-2 list-inline "
                            modules={[Navigation]}
                            slidesPerView={5}
                            spaceBetween={32}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev'
                            }}
                            breakpoints={{
                                320: { slidesPerView: 1 },
                                550: { slidesPerView: 2 },
                                991: { slidesPerView: 3 },
                                1400: { slidesPerView: 4 },
                                1500: { slidesPerView: 5 },
                                1920: { slidesPerView: 6 },
                                2040: { slidesPerView: 7 },
                                2440: { slidesPerView: 8 }
                            }} data-aos="fade-up" data-aos-delay="700"
                        >
                            <SwiperSlide className="card card-slide" >
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="bg-info text-white rounded p-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                            </svg>
                                        </div>
                                        <div className="text-end">
                                            Impressions
                                            <h4 className="counter"><CountUp start={0} end={imp} duration={3} decimals={1} />{unit}</h4>
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>
                            <SwiperSlide className=" card card-slide" >
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="bg-primary text-white rounded p-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mouse2-fill" viewBox="0 0 16 16">
                                                <path d="M7.5.026C4.958.286 3 2.515 3 5.188V5.5h4.5V.026zm1 0V5.5H13v-.312C13 2.515 11.042.286 8.5.026zM13 6.5H3v4.313C3 13.658 5.22 16 8 16s5-2.342 5-5.188V6.5z" />
                                            </svg>
                                        </div>
                                        <div className="text-end">
                                            Clicks
                                            <h4 className="counter"><CountUp start={0} end={click} duration={3} separator={","} />{cunit}</h4>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className=" card card-slide" >
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="bg-info text-white rounded p-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-credit-card-fill" viewBox="0 0 16 16">
                                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z" />
                                            </svg>
                                        </div>
                                        <div className="text-end">
                                            Conversions
                                            <h4 className="counter"><CountUp start={0} end={conv} duration={3} separator={","} />{vunit}</h4>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className=" card card-slide" >
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="bg-primary text-white rounded p-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-index-fill" viewBox="0 0 16 16">
                                                <path d="M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v5.34l-1.2.24a1.5 1.5 0 0 0-1.196 1.636l.345 3.106a2.5 2.5 0 0 0 .405 1.11l1.433 2.15A1.5 1.5 0 0 0 6.035 16h6.385a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002z" />
                                            </svg>
                                        </div>
                                        <div className="text-end">
                                            CTR
                                            <h4 className="counter"><CountUp start={0} end={ctr} decimals={2} duration={3} />%</h4>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className=" card card-slide" >
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="bg-info text-white rounded p-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                            </svg>
                                        </div>
                                        <div className="text-end">
                                            Cost
                                            <h4 className="counter"><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={kpi.cost} duration={3} separator={","} /></h4>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className=" card card-slide" >
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="bg-primary text-white rounded p-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                            </svg>
                                        </div>
                                        <div className="text-end">
                                            aCPA
                                            <h4 className="counter"><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={cpa} duration={3} decimals={2} /></h4>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className=" card card-slide">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="bg-info text-white rounded p-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                            </svg>
                                        </div>
                                        <div className="text-end">
                                            aCPM
                                            <h4 className="counter"><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={cpm} duration={3} decimals={2} /></h4>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className=" card card-slide" >
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="bg-primary text-white rounded p-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                            </svg>
                                        </div>
                                        <div className="text-end">
                                            aCPC
                                            <h4 className="counter"><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={cpc} duration={3} decimals={2} /></h4>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {(dash.kpi.lpv) ? (<>
                                <SwiperSlide className=" card card-slide" >
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="bg-info text-white rounded p-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                </svg>
                                            </div>
                                            <div className="text-end">
                                                LPV
                                                <h4 className="counter"><CountUp start={0} end={lpv} duration={3} decimals={0} /></h4>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </>) : (<></>)}
                            <div className="swiper-button swiper-button-next"></div>
                            <div className="swiper-button swiper-button-prev"></div>
                        </Swiper>
                    </div>
                </Row>
            </Col>
            {(dash.region) ? (


                <Col md="12" lg="8">
                    <Row>
                        <Col md="12">
                            <div className="card" data-aos="fade-up" data-aos-delay="800">
                                <div className="flex-wrap card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Performance by Region</h4>
                                    </div>
                                    <div className="d-flex align-items-center align-self-center">
                                        <div className="d-flex align-items-center text-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 24 24" fill="currentColor">
                                                <g>
                                                    <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                                                </g>
                                            </svg>
                                            <div className="ms-2">
                                                <span className="text-secondary">Clicks</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center ms-3 text-info">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 24 24" fill="currentColor">
                                                <g>
                                                    <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                                                </g>
                                            </svg>
                                            <div className="ms-2">
                                                <span className="text-secondary">Cost</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center ms-3 text-warning">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 24 24" fill="currentColor">
                                                <g>
                                                    <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                                                </g>
                                            </svg>
                                            <div className="ms-2">
                                                <span className="text-secondary">Conversions</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Dropdown>
                                            <Dropdown.Toggle as={Button} href="#" variant=" text-secondary dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                                This Week
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton2">
                                                <li><Dropdown.Item href="#">This Week</Dropdown.Item></li>
                                                <li><Dropdown.Item href="#">This Month</Dropdown.Item></li>
                                                <li><Dropdown.Item href="#">This Year</Dropdown.Item></li>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                </div>
                                <div className="card-body">
                                    <Chart options={chart1.options} series={chart1.series} type="bar" height="300" />
                                </div>
                            </div>
                        </Col>
                        {/* <Col md="12" xl="6">
                                <div className="card" data-aos="fade-up" data-aos-delay="900">
                                    <div className="flex-wrap card-header d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Cost by Age</h4>
                                        </div>
                                      
                                    </div>
                                    <div className="card-body">
                                        <div className="flex-wrap d-flex align-items-center justify-content-between">
                                            <Chart className="col-12" options={chart4.options} series={chart4.series} type="donut" height="265" />
                                        </div>
                                    </div>
                                </div>
                            </Col> */}
                        {/* <Col md="12" xl="6">
                                <div className="card" data-aos="fade-up" data-aos-delay="900">
                                    <div className="flex-wrap card-header d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Gender</h4>            
                                        </div>                   
                                    </div>
                                    <div className="card-body">
                                        <div className="flex-wrap d-flex align-items-center justify-content-between">
                                            <Chart className="col-md-8 col-lg-8" options={chart5.options} series={chart5.series} type="radialBar"   height="265"  />
                                            <div className="d-grid gap col-md-4 col-lg-4">
                                                <div className="d-flex align-items-start">
                                                <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#3a57e8">
                                                    <g>
                                                        <circle cx="12" cy="12" r="8" fill="#3a57e8"></circle>
                                                    </g>
                                                </svg>
                                                <div className="ms-3">
                                                    <span className="text-secondary">Male</span>
                                                    <h6><CountUp start={0} end={dash.gender["Male"].impressions}  duration={3} decimals={0} />{aunit}</h6>
                                                </div>
                                                </div>
                                                <div className="d-flex align-items-start">
                                                <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#4bc7d2">
                                                    <g>
                                                        <circle cx="12" cy="12" r="8" fill="#4bc7d2"></circle>
                                                    </g>
                                                </svg>
                                                <div className="ms-3">
                                                    <span className="text-secondary">Female</span>
                                                    <h6><CountUp start={0} end={dash.gender["Female"].impressions}  separator={","} duration={3} decimals={0} /></h6>
                                                </div>
                                                <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#4bc7d2">
                                                    <g>
                                                        <circle cx="12" cy="12" r="8" fill="#F16A1B"></circle>
                                                    </g>
                                                </svg>
                                                <div className="ms-3">
                                                    <span className="text-secondary">Unknown</span>
                                                    <h6><CountUp start={0} end={dash.gender["Unknown"].impressions}  separator={","} duration={3} decimals={0} /></h6>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col> */}

                    </Row>
                </Col>
            ) : (<></>)}
            {(dash.cdp) ? (
                <Col lg="4" sm="12">
                    <Row>
                        <Col md="12" lg="12">
                            <div className="overflow-hidden card" data-aos="fade-up" data-aos-delay="600">
                                <div className="flex-wrap card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="mb-2 card-title">CDP - Coming Soon</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="mt-3">
                                            <Row>

                                                {/* {Object.keys(dash.exchanges).map((name, i) => {
                                                return (
                                                    <Col lg="4">
                                                    <div className="pb-3" key={name}>
                                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                                    <p className="mb-0">{name}</p>
                                                    <h4 className="counter"><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={dash.exchanges[name].cost} duration={3} separator={","} /></h4>
                                                    </div>
                                                    <div className="px-3">
                                                    <p className="mb-0">Cost</p>
                                                    <Progress softcolors="info" color="info" className="shadow-none w-100" value={(dash.exchanges[name].cost / kpi.cost) * 100} minvalue={0} maxvalue={100} style={{ height: "10px" }} />
                                                    <p className="mb-0">Impressions</p>
                                                    <Progress softcolors="success" color="success" className="shadow-none w-100" value={(dash.exchanges[name].impressions / kpi.impressions) * 100} minvalue={0} maxvalue={100} style={{ height: "10px" }} />
                                                    <p className="mb-0">Clicks</p>
                                                    <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={(dash.exchanges[name].clicks / kpi.clicks) * 100} minvalue={0} maxvalue={100} style={{ height: "10px" }} />
                                                    <p className="mb-0">Conversions</p>
                                                    <Progress softcolors="warning" color="warning" className="shadow-none w-100" value={(dash.exchanges[name].conversions / kpi.conversions) * 100} minvalue={0} maxvalue={100} style={{ height: "10px" }} />
                                                    </div>
                                                    </div>
                                                    </Col>
                                                )
                                            })} */}
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            ) : (<></>)}
            {(dash.channels) ? (

                <Col md="12" xl="4">
                    <div className="card" data-aos="fade-up" data-aos-delay="900">
                        <div className="flex-wrap card-header d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Cost by Channel</h4>
                            </div>
                            {/* <Dropdown>
                                            <Dropdown.Toggle as={Button} href="#" variant=" text-secondary" id="dropdownMenuButton1" aria-expanded="false">
                                            This Week
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className=" dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                            <li><Dropdown.Item href="#">This Week</Dropdown.Item></li>
                                            <li><Dropdown.Item href="#">This Month</Dropdown.Item></li>
                                            <li><Dropdown.Item href="#">This Year</Dropdown.Item></li>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                        </div>
                        <div className="card-body">
                            <div className="flex-wrap d-flex align-items-center justify-content-between">
                                <Chart className="col-12" options={chart2.options} series={chart2.series} type="donut" height="265" />
                            </div>
                        </div>
                    </div>
                </Col>
            ) : (<></>)}
            {(dash.media) ? (
                <Col md="12" xl="4">
                    <div className="card" data-aos="fade-up" data-aos-delay="900">
                        <div className="flex-wrap card-header d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Cost by Media</h4>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="flex-wrap d-flex align-items-center justify-content-between">
                                <Chart className="col-12" options={media.options} series={media.series} type="donut" height="265" />
                            </div>
                        </div>
                    </div>
                </Col>
            ) : (<></>)}
            {(dash.browsers) ? (

                <Col md="12" xl="4">
                    <div className="card" data-aos="fade-up" data-aos-delay="900">
                        <div className="flex-wrap card-header d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Cost by Browser</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="flex-wrap d-flex align-items-center justify-content-between">
                                <Chart className="col-12" options={browser.options} series={browser.series} type="donut" height="265" />
                            </div>
                        </div>
                    </div>
                </Col>
            ) : (<></>)}
            {(dash.os) ? (

                <Col md="12" xl="4">
                    <div className="card" data-aos="fade-up" data-aos-delay="900">
                        <div className="flex-wrap card-header d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Cost by OS</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="flex-wrap d-flex align-items-center justify-content-between">
                                <Chart className="col-12" options={os.options} series={os.series} type="donut" height="265" />
                            </div>
                        </div>
                    </div>
                </Col>
            ) : (<></>)}
            {(dash.age) ? (

                <Col md="12" xl="4">
                    <div className="card" data-aos="fade-up" data-aos-delay="900">
                        <div className="flex-wrap card-header d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Cost by Age</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="flex-wrap d-flex align-items-center justify-content-between">
                                <Chart className="col-12" options={age.options} series={age.series} type="donut" height="265" />
                            </div>
                        </div>
                    </div>
                </Col>
            ) : (<></>)}
            {(dash.gender) ? (

                <Col md="12" xl="4">
                    <div className="card" data-aos="fade-up" data-aos-delay="900">
                        <div className="flex-wrap card-header d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Cost by Gender</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="flex-wrap d-flex align-items-center justify-content-between">
                                <Chart className="col-12" options={gender.options} series={gender.series} type="donut" height="265" />
                            </div>
                        </div>
                    </div>
                </Col>
            ) : (<></>)}
            {(dash.anon.cost) ? (

                <Col md="12" xl="4">
                    <div className="card" data-aos="fade-up" data-aos-delay="900">
                        <div className="flex-wrap card-header d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Unknown Users</h4>
                            </div>
                            {/* <Dropdown>
                                            <Dropdown.Toggle as={Button} href="#" variant=" text-secondary" id="dropdownMenuButton1" aria-expanded="false">
                                                This Week
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className=" dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                <li><Dropdown.Item href="#">This Week</Dropdown.Item></li>
                                                <li><Dropdown.Item href="#">This Month</Dropdown.Item></li>
                                                <li><Dropdown.Item href="#">This Year</Dropdown.Item></li>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                        </div>
                        <div className="card-body">
                            <div className="flex-wrap d-flex align-items-center justify-content-between">
                                <Chart className="col-md-8 col-lg-8" options={chart3.options} series={chart3.series} type="radialBar" height="265" />
                                <div className="d-grid gap col-md-4 col-lg-4">
                                    <div className="d-flex align-items-start">
                                        <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#3a57e8">
                                            <g>
                                                <circle cx="12" cy="12" r="8" fill="#3a57e8"></circle>
                                            </g>
                                        </svg>
                                        <div className="ms-3">
                                            <span className="text-secondary">Impressions</span>
                                            <h6><CountUp start={0} end={aimp} duration={3} decimals={1} />{aunit}</h6>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-start">
                                        <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#4bc7d2">
                                            <g>
                                                <circle cx="12" cy="12" r="8" fill="#4bc7d2"></circle>
                                            </g>
                                        </svg>
                                        <div className="ms-3">
                                            <span className="text-secondary">Cost</span>
                                            <h6><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={dash.anon.cost} separator={","} duration={3} decimals={0} /></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            ) : (<></>)}

            {(dash.deleted) ? (


                <Col md="12" xl="4">
                    <div className="card" data-aos="fade-up" data-aos-delay="900">
                        <div className="flex-wrap card-header d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Deleted Youtube</h4>
                            </div>
                            {/* <Dropdown>
                                            <Dropdown.Toggle as={Button} href="#" variant=" text-secondary" id="dropdownMenuButton1" aria-expanded="false">
                                                This Week
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className=" dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                <li><Dropdown.Item href="#">This Week</Dropdown.Item></li>
                                                <li><Dropdown.Item href="#">This Month</Dropdown.Item></li>
                                                <li><Dropdown.Item href="#">This Year</Dropdown.Item></li>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                        </div>
                        <div className="card-body">
                            <div className="flex-wrap d-flex align-items-center justify-content-between">
                                <Chart className="col-md-8 col-lg-8" options={chart4.options} series={chart4.series} type="radialBar" height="265" />
                                <div className="d-grid gap col-md-4 col-lg-4">
                                    <div className="d-flex align-items-start">
                                        <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#3a57e8">
                                            <g>
                                                <circle cx="12" cy="12" r="8" fill="#3a57e8"></circle>
                                            </g>
                                        </svg>
                                        <div className="ms-3">
                                            <span className="text-secondary">Impressions</span>
                                            <h6><CountUp start={0} end={dash.deleted.deleted.impressions} duration={3} decimals={0} /></h6>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-start">
                                        <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#4bc7d2">
                                            <g>
                                                <circle cx="12" cy="12" r="8" fill="#4bc7d2"></circle>
                                            </g>
                                        </svg>
                                        <div className="ms-3">
                                            <span className="text-secondary">Videos</span>
                                            <h6><CountUp start={0} end={dash.deleted.deleted.trueview} separator={","} duration={3} decimals={0} /></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            ) : (<></>)}
            <Col md="12" lg="12">
                <Row>
                    <Col md="12" lg="12">
                        <div className="overflow-hidden card" data-aos="fade-up" data-aos-delay="600">
                            <div className="flex-wrap card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="mb-2 card-title">Performance By Exchange</h4>
                                </div>
                                <div className="card-body">
                                    <div className="mt-3">
                                        <Row>

                                            {Object.keys(dash.exchanges).map((name, i) => {
                                                let metric = buildExchanges(dash.exchanges[name])
                                                return (
                                                    <Col lg="3">
                                                        <div className="pb-3" key={name}>
                                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                                <p className="mb-0">{name}</p>
                                                                <h4 className="counter"><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={metric.cost} duration={3} separator={","} /></h4>
                                                            </div>
                                                            <div className="px-3">
                                                                <p className="mb-0">Cost</p>
                                                                <Progress softcolors="info" color="info" className="shadow-none w-100" value={(metric.cost / kpi.cost) * 100} minvalue={0} maxvalue={100} style={{ height: "10px" }} />
                                                                <p className="mb-0">Impressions</p>
                                                                <Progress softcolors="success" color="success" className="shadow-none w-100" value={(metric.impressions / kpi.impressions) * 100} minvalue={0} maxvalue={100} style={{ height: "10px" }} />
                                                                <p className="mb-0">Clicks</p>
                                                                <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={(metric.clicks / kpi.clicks) * 100} minvalue={0} maxvalue={100} style={{ height: "10px" }} />
                                                                <p className="mb-0">Conversions</p>
                                                                <Progress softcolors="warning" color="warning" className="shadow-none w-100" value={(metric.conversions / kpi.conversions) * 100} minvalue={0} maxvalue={100} style={{ height: "10px" }} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
            {(subExchangeExists(dash)) ? (<>
                <Col md="12" lg="12">
                    <Row>
                        <Col md="12" lg="12">
                            <div className="overflow-hidden card" data-aos="fade-up" data-aos-delay="600">
                                <div className="flex-wrap card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="mb-2 card-title">Performance By Sub Exchange</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="mt-3">
                                            <Row>
                                                {Object.keys(dash.exchanges).map((name, i) => {
                                                    let keys = Object.keys(dash.exchanges[name])
                                                    if (keys.length > 1) {
                                                        return (
                                                            Object.keys(dash.exchanges[name]).map((name2, i) => {
                                                                let metric = dash.exchanges[name][name2]
                                                                return (
                                                                    <Col lg="3">
                                                                        <div className="pb-3" key={name2}>
                                                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                                                <p className="mb-0">{name2} - {name}</p>
                                                                                <h4 className="counter"><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={metric.cost} duration={3} separator={","} /></h4>
                                                                            </div>
                                                                            <div className="px-3">
                                                                                <p className="mb-0">Cost</p>
                                                                                <Progress softcolors="info" color="info" className="shadow-none w-100" value={(metric.cost / kpi.cost) * 100} minvalue={0} maxvalue={100} style={{ height: "10px" }} />
                                                                                <p className="mb-0">Impressions</p>
                                                                                <Progress softcolors="success" color="success" className="shadow-none w-100" value={(metric.impressions / kpi.impressions) * 100} minvalue={0} maxvalue={100} style={{ height: "10px" }} />
                                                                                <p className="mb-0">Clicks</p>
                                                                                <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={(metric.clicks / kpi.clicks) * 100} minvalue={0} maxvalue={100} style={{ height: "10px" }} />
                                                                                <p className="mb-0">Conversions</p>
                                                                                <Progress softcolors="warning" color="warning" className="shadow-none w-100" value={(metric.conversions / kpi.conversions) * 100} minvalue={0} maxvalue={100} style={{ height: "10px" }} />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            })
                                                        )
                                                    }
                                                })}
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </>) : (<></>)}
            {/* <Col md="12" lg="12">
                <div className="card" data-aos="fade-up" data-aos-delay="600">
                    <div className="flex-wrap card-header d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="mb-2 card-title">Unknown Traffic Breakdown</h4>
                        </div>
                        <div className="card-body  mw-100">
                            <div className="mt-3">
                                <ReactTable item={data5} col={columns} page_size={20} row_size={20} />
                            </div>
                        </div>
                    </div>
                </div>
            </Col> */}
            <Col lg="12">
                <Row>
                    {(!dash.campaign) ? (<></>) : (

                        <Col md="12" lg="12">
                            <div className="overflow-hidden card" data-aos="fade-up" data-aos-delay="600">
                                <div className="flex-wrap card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="mb-2 card-title">Performance By Campaign</h4>
                                    </div>
                                </div>
                                <div className="p-0 card-body">
                                    <div className="mt-4 table-responsive">
                                        <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                            <thead>
                                                <tr>
                                                    <th>CAMPAIGN</th>
                                                    <th>IMPRESSIONS</th>
                                                    <th>CLICKS</th>
                                                    <th>CONVERSIONS</th>
                                                    <th>COST</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {Object.keys(dash.campaign).map((id, i) => {

                                                    let campaign = dash.campaign[id]
                                                    return (<tr key={id}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <h6><a href={"/app/campaign/" + campaign.platform + "/" + campaign._id}>{(campaign.name) ? (campaign.name.length > 60) ? (campaign.name?.substring(0, 50) + "...") : (campaign.name) : (<></>)}</a></h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img className={"img-platform-logo"} alt={"img-platform-logo"} src={determineLogo(campaign.platform)} />
                                                            </div>
                                                        </td>
                                                        <td>

                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={campaign.impressions} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="success" color="success" className="shadow-none w-100" value={(campaign.impressions / kpi.impressions) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />

                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={campaign.clicks} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={(campaign.clicks / kpi.clicks) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={campaign.conversions} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="warning" color="warning" className="shadow-none w-100" value={(campaign.conversions / kpi.conversions) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>

                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={campaign.cost} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="info" color="info" className="shadow-none w-100" value={(campaign.cost / kpi.cost) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                    </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )}
                    {(!dash.order) ? (<></>) : (
                        <Col md="12" lg="12">
                            <div className="overflow-hidden card" data-aos="fade-up" data-aos-delay="600">
                                <div className="flex-wrap card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="mb-2 card-title">Performance By Order</h4>
                                    </div>
                                </div>
                                <div className="p-0 card-body">
                                    <div className="mt-4 table-responsive">
                                        <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                            <thead>
                                                <tr>
                                                    <th>ORDER</th>
                                                    <th>PLATFORM</th>
                                                    <th>IMPRESSIONS</th>
                                                    <th>CLICKS</th>
                                                    <th>CONVERSIONS</th>
                                                    <th>COST</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(dash.order).map((id, i) => {

                                                    return (<tr key={id}>

                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <h6><a href={"/app/order/" + dash.order[id].platform + "/" + dash.order[id]._id}>{(dash.order[id].name) ? (dash.order[id].name.length > 60) ? (dash.order[id].name?.substring(0, 60) + "...") : (dash.order[id].name) : (<></>)}</a></h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img className={"img-platform-logo"} alt={"img-platform-logo"} src={determineLogo(dash.order[id].platform)} />
                                                            </div>
                                                        </td>
                                                        <td>

                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={dash.order[id].impressions} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="success" color="success" className="shadow-none w-100" value={(dash.order[id].impressions / kpi.impressions) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />

                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={dash.order[id].clicks} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={(dash.order[id].clicks / kpi.clicks) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={dash.order[id].conversions} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="warning" color="warning" className="shadow-none w-100" value={(dash.order[id].conversions / kpi.conversions) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>

                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={dash.order[id].cost} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="info" color="info" className="shadow-none w-100" value={(dash.order[id].cost / kpi.cost) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                    </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Col>)}
                    {(!dash.group) ? (<></>) : (
                        <Col md="12" lg="12">
                            <div className="overflow-hidden card" data-aos="fade-up" data-aos-delay="600">
                                <div className="flex-wrap card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="mb-2 card-title">Performance By Group</h4>
                                    </div>
                                </div>
                                <div className="p-0 card-body">
                                    <div className="mt-4 table-responsive">
                                        <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                            <thead>
                                                <tr>
                                                    <th>GROUP</th>
                                                    <th>PLATFORM</th>
                                                    <th>IMPRESSIONS</th>
                                                    <th>CLICKS</th>
                                                    <th>CONVERSIONS</th>
                                                    <th>COST</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(dash.group).map((id, i) => {
                                                    let group = dash.group[id]
                                                    return (<tr key={id}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <h6><a href={"/app/group/" + group.platform + "/" + group._id}>{(group.name) ? (group.name.length > 60) ? (group.name?.substring(0, 60) + "...") : (group.name) : (<></>)}</a></h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img className={"img-platform-logo"} alt={"img-platform-logo"} src={determineLogo(group.platform)} />
                                                            </div>
                                                        </td>
                                                        <td>

                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={group.impressions} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="success" color="success" className="shadow-none w-100" value={(group.impressions / kpi.impressions) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />

                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={group.clicks} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={(group.clicks / kpi.clicks) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={group.conversions} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="warning" color="warning" className="shadow-none w-100" value={(group.conversions / kpi.conversions) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>

                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={group.cost} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="info" color="info" className="shadow-none w-100" value={(group.cost / kpi.cost) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                    </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Col>)}
                    {(dash.properties) ? (
                        <Col md="12" lg="12">
                            <div className="overflow-hidden card" data-aos="fade-up" data-aos-delay="600">
                                <div className="flex-wrap card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="mb-2 card-title">Performance By Web Properties</h4>
                                    </div>
                                </div>
                                <div className="p-0 card-body">
                                    <div className="mt-4 table-responsive">
                                        <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                            <thead>
                                                <tr>
                                                    <th>PROPERTY</th>
                                                    <th>IMPRESSIONS</th>
                                                    <th>CLICKS</th>
                                                    <th>CONVERSIONS</th>
                                                    <th>COST</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(dash.properties).map(function (name, i) {


                                                    return (<tr key={name}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
                                                                    <div onClick={() => webPreview(name)}><h6 className="button" >{name?.substring(0, 70)}</h6></div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={dash.properties[name].impressions} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="success" color="success" className="shadow-none w-100" value={(dash.properties[name].impressions / kpi.impressions) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />

                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={dash.properties[name].clicks} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={(dash.properties[name].clicks / kpi.clicks) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={dash.properties[name].conversions} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="warning" color="warning" className="shadow-none w-100" value={(dash.properties[name].conversions / kpi.conversions) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>

                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={dash.properties[name].cost} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="info" color="info" className="shadow-none w-100" value={(dash.properties[name].cost / kpi.cost) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                    </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ) : (<></>)}
                    {(dash.apps) ? (
                        <Col md="12" lg="12">
                            <div className="overflow-hidden card" data-aos="fade-up" data-aos-delay="600">
                                <div className="flex-wrap card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="mb-2 card-title">Performance By App Properties</h4>
                                    </div>
                                </div>
                                <div className="p-0 card-body">
                                    <div className="mt-4 table-responsive">
                                        <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                            <thead>
                                                <tr>
                                                    <th>APP</th>
                                                    <th>IMPRESSIONS</th>
                                                    <th>CLICKS</th>
                                                    <th>CONVERSIONS</th>
                                                    <th>COST</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(dash.apps).map(function (name, i) {

                                                    // let resp = RetrievePreview("https://+"+name)
                                                    // console.log(resp)                                                
                                                    const popover = (

                                                        <div>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <Card className="bg-dark text-white lg-image" >
                                                                        <iframe src={"https://" + name} width="800" height="700" />
                                                                        <Card.Body>
                                                                            <Card.Title><a href={"https://" + name} target="_blank">{name}</a></Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    );
                                                    return (<tr key={name}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
                                                                    <h6 className="button">{name?.substring(0, 70)}</h6>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                        <td>

                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={dash.apps[name].impressions} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="success" color="success" className="shadow-none w-100" value={(dash.apps[name].impressions / kpi.impressions) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />

                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={dash.apps[name].clicks} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={(dash.apps[name].clicks / kpi.clicks) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={dash.apps[name].conversions} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="warning" color="warning" className="shadow-none w-100" value={(dash.apps[name].conversions / kpi.conversions) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>

                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={dash.apps[name].cost} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="info" color="info" className="shadow-none w-100" value={(dash.apps[name].cost / kpi.cost) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                    </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ) : (<></>)}
                    {(dash.youtube) ? (

                        <Col md="12" lg="12">
                            <div className="overflow-hidden card" data-aos="fade-up" data-aos-delay="600">
                                <div className="flex-wrap card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="mb-2 card-title">Performance By YouTube (Top 20)</h4>
                                    </div>
                                </div>
                                <div className="p-0 card-body">
                                    <div className="mt-4 table-responsive">
                                        <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                            <thead>
                                                <tr>
                                                    <th>VIDEO</th>
                                                    <th>IMPRESSIONS</th>
                                                    <th>TrueView</th>
                                                    <th>TrueView Rate</th>
                                                    <th>COST</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dash.youtube.map(function (video, i) {
                                                    let ytMetrics = dash.properties["youtube.com"]
                                                    let date = new Date(video.details.published)
                                                    let newDate = date.toISOString().split('T')[0]

                                                    const durationFormat = (duration) => {
                                                        let hours;
                                                        let minutes;
                                                        let seconds;
                                                        let hourArr = duration.split("H")
                                                        let minArr;
                                                        let newDuration = "";
                                                        if (hourArr.length > 1) {
                                                            hours = hourArr[0].split("T")[1]
                                                            minArr = hourArr[1].split("M")
                                                            minutes = minArr[0]
                                                        } else {
                                                            minArr = duration.split("M")
                                                            minutes = minArr[0].split("T")[1]
                                                        }
                                                        if (minArr.length > 1) {
                                                            seconds = minArr[1].split("S")[0]

                                                        } else {
                                                            if (duration != "P0D") {

                                                                seconds = duration.split("S")[0].split("T")[1]
                                                            }
                                                        }
                                                        if (typeof hours !== "undefined") {
                                                            newDuration += hours + " Hrs "
                                                        }
                                                        if (typeof minutes !== "undefined") {
                                                            newDuration += minutes + " Min "
                                                        }
                                                        if (typeof seconds !== "undefined") {
                                                            newDuration += seconds + " Sec "
                                                        }

                                                        return newDuration
                                                    }
                                                    const topics = (topic) => {
                                                        let tpc = topic.split("wiki/")[1]
                                                        return (
                                                            <Badge pill bg="dark">{tpc}</Badge>
                                                        )
                                                    }
                                                    let duration = durationFormat(video.details.duration)
                                                    const popover = (
                                                        <div>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <Card className="bg-dark text-white md-image" >
                                                                        <Card.Img variant="top" src={"https://img.youtube.com/vi/" + video.details.id + "/0.jpg"} />
                                                                        <Card.Body>
                                                                            <Card.Title><a href={"https://youtube.com/video/" + video.details.id} target="_blank">{video.details.title}</a></Card.Title>
                                                                            <Card.Subtitle><a href={'https://youtube.com/channel/' + video.details.channel_id} target='_blank'>{video.details.channel_title}</a></Card.Subtitle>
                                                                            <Row className="mt-2">
                                                                                <Col lg="6">
                                                                                    <strong>Privacy:</strong> {video.details.privacy}
                                                                                </Col>
                                                                                <Col lg="6">
                                                                                    <strong>Published:</strong> {newDate}
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg="6">
                                                                                    <strong>Language:</strong> {video.details.language}
                                                                                </Col>
                                                                                <Col lg="6">
                                                                                    <strong>Duration:</strong> {duration}
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg="6">
                                                                                    <strong>Live Stream:</strong> {(video.details.live) ? "Yes" : "No"}
                                                                                </Col>
                                                                                <Col lg="6">
                                                                                    <strong>Likes:</strong> {FormatNumber(video.details.likes)}
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg="6">
                                                                                    <strong>Made For Kids:</strong> {(video.details.kids) ? "Yes" : "No"}
                                                                                </Col>
                                                                                <Col lg="6">
                                                                                    <strong>Views:</strong> {FormatNumber(video.details.views)}
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                        <Card.Footer className="text-muted bg-secondary">
                                                                            <Stack direction="horizontal" gap={2}>
                                                                                {video.details.topics.map(function (topic, i) {
                                                                                    return (topics(topic))
                                                                                })}
                                                                            </Stack>
                                                                        </Card.Footer>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    );
                                                    return (<tr key={video.details.id}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
                                                                    <h6 className="button">{video.details.title?.substring(0, 70)}</h6>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                        <td>

                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={video.metrics.impressions} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="success" color="success" className="shadow-none w-100" value={(video.metrics.impressions / ytMetrics.impressions) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />

                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={video.metrics.trueview} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={(video.metrics.trueview / ytMetrics.trueview) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CountUp start={0} end={video.metrics.trueview / video.metrics.impressions * 100} duration={3} separator={","} />%</h6>
                                                            </div>
                                                            <Progress softcolors="warning" color="warning" className="shadow-none w-100" value={(video.metrics.trueview / video.metrics.impressions) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                        <td>
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <h6><CurrencySymbol cur={account.data.currency}></CurrencySymbol><CountUp start={0} end={video.metrics.cost} duration={3} separator={","} /></h6>
                                                            </div>
                                                            <Progress softcolors="info" color="info" className="shadow-none w-100" value={(video.metrics.cost / ytMetrics.cost) * 100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                        </td>
                                                    </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ) : (<><span>Youtube data either doesn't exist or isn't available. (Note: There is no campaign level YouTube breakouts please navigate to Order or Group levels)</span></>)}
                </Row>
            </Col>
        </Row>
    )
}
export default Dash
