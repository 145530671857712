import { React, useEffect, useContext, useState } from 'react'
import { Row, Col, Tab, Nav, Alert } from 'react-bootstrap'

import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { BuildOptions, TrimString, CountEntities, DateFormat, Micros, SetTargeting, SortByString } from '../../../components/common'
import ReactTable from '../../../components/table'

import { useParams } from 'react-router-dom'
import { FetchFromAPI, RetrieveTargetingList } from '../../../components/ajx'
import Dash from '../../../components/dash';
import { columnsGroup } from '../../../components/gridColumns'

// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'
import Automizer from '../../../components/automizer'
import { DateButtons } from '../../../components/dateButtons';
import { GroupModel, OrderModel, TargetingModel } from '../../../components/Models'
import { FindAndSet, TargetingForm } from '../../../util/targeting-order-input'
import Crumbs from '../../../components/crumbs'
import { ModalButton } from '../../../components/ModalSettings'
import { GroupModal } from '../../../components/Modals'
import { GroupForm } from '../../../util/group-input'
import { OrderForm } from '../../../util/order-input'
import InputSettings from '../../../components/InputSettings'
import { useAppContext } from '../../../context/appContext'

// install Swiper modules

const Order = (props) => {
    const appValue = useAppContext();
    const [order, setOrder] = useState(OrderModel)
    const [group, setGroup] = useState(GroupModel)
    const [modal2, setModal2] = useState(false);
    const { oid } = useParams()
    const [initialLoad, setInitialLoad] = useState(true)
    const [defaultValue, setDefaultValue] = useState()
    const [alert, setAlert] = useState({ variant: "", value: "" })
    const [targeting, setTargeting] = useState(TargetingModel)


    useEffect(() => {

        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    }, [])

    var REACT_APP_API_URL = appValue.apiUrl;

    const rearrangeTargeting = (targeting) => {
        var newTargeting = []
        if (targeting && targeting.length >= 1) {

            targeting.map((t) => {
                newTargeting.push(t)
            })
        }

        return newTargeting
    }



    let url = REACT_APP_API_URL + "/order/update/" + oid

    var payload = {
        "targeting_types": ["TARGETING_TYPE_SUB_EXCHANGE", "TARGETING_TYPE_LANGUAGE",
            "TARGETING_TYPE_CATEGORY",
            "TARGETING_TYPE_DEVICE_MAKE_MODEL", "TARGETING_TYPE_BROWSER", "TARGETING_TYPE_OPERATING_SYSTEM"]
    }

    var targetingList = RetrieveTargetingList(payload)
    const [date, setDate] = useState(1)
    const [dashAPI, setDashAPI] = useState({
        urls: [
            {
                name: "order",
                url: REACT_APP_API_URL + "/order/" + oid + "/" + "DV"
            },
            {
                name: "targeting",
                url: REACT_APP_API_URL + "/lib/targeting"
            },
            {
                name: "properties",
                url: REACT_APP_API_URL + "/property/list"
            },
            {
                name: "groups",
                url: REACT_APP_API_URL + "/group/list/order/" + oid
            },
            {
                name: "dash",
                url: REACT_APP_API_URL + "/reports/dash/order/" + appValue.user.apid + "/" + oid + "?date=" + date
            },
            {
                name: "account",
                url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
            },
            {
                name: "creatives",
                url: REACT_APP_API_URL + "/creative/list/small"
            },
            {
                name: "crumbs",
                url: REACT_APP_API_URL + "/util/breadcrumb/orders/DV/" + oid
            }
        ],
        method: "GET",

    })
    useEffect(() => {
        complete = false
        setDashAPI({
            urls: [
                {
                    name: "order",
                    url: REACT_APP_API_URL + "/order/" + oid + "/" + "DV"
                },
                {
                    name: "targeting",
                    url: REACT_APP_API_URL + "/lib/targeting"
                },
                {
                    name: "properties",
                    url: REACT_APP_API_URL + "/property/list"
                },
                {
                    name: "groups",
                    url: REACT_APP_API_URL + "/group/list/order/" + oid
                },
                {
                    name: "dash",
                    url: REACT_APP_API_URL + "/reports/dash/order/" + appValue.user.apid + "/" + oid + "?date=" + date
                },
                {
                    name: "account",
                    url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
                },
                {
                    name: "creatives",
                    url: REACT_APP_API_URL + "/creative/list/small"
                },
                {
                    name: "crumbs",
                    url: REACT_APP_API_URL + "/util/breadcrumb/orders/DV/" + oid
                }
            ],
            method: "GET",
    
        })
    }, [date])

    const { NODE_ENV } = process.env;

    var { results, errors, complete } = FetchFromAPI(dashAPI)


    useEffect(() => {
        if (complete) {
            if (results["order"].data.dv_targeting) {
                let targeting = rearrangeTargeting(results["order"].data.dv_targeting.assignedTargetingOptions)
                setDefaultValue(targeting)
                // console.log(defaultValue)
            }
        }
    }, [complete])


    // useEffect(() => {
    //     // This sets up the fields to be preselected once the default values have been received
    //     const defaultTypes = {
    //         demographics: ['TARGETING_TYPE_GENDER', 'TARGETING_TYPE_AGE_RANGE', 'TARGETING_TYPE_PARENTAL_STATUS', 'TARGETING_TYPE_HOUSEHOLD_INCOME'],
    //         device: ['TARGETING_TYPE_DEVICE_TYPE', 'TARGETING_TYPE_ENVIRONMENT', 'TARGETING_TYPE_ON_SCREEN_POSITION', 'TARGETING_TYPE_CONTENT_OUTSTREAM_POSITION', 'TARGETING_TYPE_CONTENT_INSTREAM_POSITION', 'TARGETING_TYPE_NATIVE_CONTENT_POSITION', 'TARGETING_TYPE_DEVICE_MAKE_MODEL', 'TARGETING_TYPE_OPERATING_SYSTEM', 'TARGETING_TYPE_BROWSER', 'TARGETING_TYPE_CARRIER_AND_ISP'],
    //         location: ['TARGETING_TYPE_GEO_REGION', 'TARGETING_TYPE_LANGUAGE'],
    //         content: ['TARGETING_TYPE_CATEGORY'],
    //         inventory: ['TARGETING_TYPE_EXCHANGE', 'TARGETING_TYPE_SUB_EXCHANGE'],
    //         time: ['TARGETING_TYPE_DAY_AND_TIME']
    //     }

    //     if (defaultValue && defaultValue.length >= 1) {
    //         for (let i = 0; i < defaultValue.length; i++) {
    //             // checkForDefaultValues(defaultValue[i], defaultTypes)
    //         }
    //     }
    // }, [defaultValue])

    useEffect(() => {
        if (targetingList.targetingList.data) {
            Object.keys(targetingList.targetingList.data).forEach((key) => {
                SortByString(targetingList.targetingList.data[key], "label")
            })
            FindAndSet("browsers.opts", targetingList.targetingList.data.browser)
            FindAndSet("os.opts", targetingList.targetingList.data.os)
            FindAndSet("languages.opts", targetingList.targetingList.data.language)
        }

    }, [targetingList])

    // const checkForDefaultValues = (val, targets) => {
    //     for (const t in targets) {
    //         var properties = targets[t]
    //         addTargetToOrder()
    //         // range through all property options to find the one that matches the defaultVal's targetingType
    //         for (let i = 0; i < properties.length; i++) {
    //             if (properties[i] === val.targetingType) {
    //                 // remove the underscores and convert to lowercase
    //                 const strArr = properties[i].split('TARGETING_TYPE_')
    //                 var property = strArr[1].replace('_', '').toLowerCase()
    //                 property = property.replace('_', '')
    //                 // after reformatting the property to all lowercase w/o underscores, add it to the target
    //                 addPropertyToTarget(t, property, val)
    //             }
    //         }
    //     }
    // }

    if (complete) {
        if (NODE_ENV == "development") {
            console.log(results, targeting, order)
        }
        if (initialLoad) {
            setInitialLoad(false)
            if (results['order'].data.dv_order) {


                order.campaign_id = (results['order'].data.dv_campaign_id) ? results['order'].data.dv_campaign_id.toString() : "";
                OrderForm.fields[0][0].options.push({ label: order.campaign_id, value: order.campaign_id })
                order.order_id = (results['order'].data.dv_order_id) ? results['order'].data.dv_order_id.toString() : "";
                order.insertion_order_type = (results['order'].data.ordertype) ? results['order'].data.ordertype : ""
                order.billable_outcome = (results['order'].data.dv_order.billableOutcome) ? results['order'].data.dv_order.billableOutcome : ""

                order.advertiser_id = (results["order"].data.dv_advertiser_id) ? results["order"].data.dv_advertiser_id.toString() : "";
                group.order_id = (results["order"].data.dv_order_id) ? results["order"].data.dv_order_id.toString() : "";
                group.order_type = order.insertion_order_type
                GroupForm.fields[0][0].options.push({ label: order.order_id, value: order.order_id })

                order.name = (results["order"].data.name) ? results["order"].data.name : "";
                order.status = (results["order"].data.dv_order.entityStatus) ? results["order"].data.dv_order.entityStatus : "";
                if (results['order'].data.dv_order.bidStrategy.maximizeSpendAutoBid) {
                    order.bidding_strategy = "Auto" || ""
                    order.auto_bid_performance_goal_type = results['order'].data.dv_order.bidStrategy.maximizeSpendAutoBid.performanceGoalType || ""
                    order.auto_bid_max_bid = (results['order'].data.dv_order.bidStrategy.maximizeSpendAutoBid.maxAverageCpmBidAmountMicros / Micros).toString() || ""
                } else {
                    order.bidding_strategy = "Fixed" || ""
                }
                // order.bidStrategy = results["order"].data.dv //broken


                if (!results["order"].data.dv_order.frequencyCap.unlimited) {
                    order.frequency_cap.unlimited = false || ""
                    order.frequency_cap.count = results["order"].data.dv_order.frequencyCap.timeUnitCount || ""
                    order.frequency_cap.time_unit = results["order"].data.dv_order.frequencyCap.timeUnit || ""
                    order.frequency_cap.max_impressions = results["order"].data.dv_order.frequencyCap.maxImpressions || ""

                } else {
                    order.frequency_cap.unlimited = true || ""
                }

                if (results["order"].data.dv_order.budget) {

                    order.budget.automation_type = results["order"].data.dv_order.budget.automationType || ""
                    order.budget.unit = results["order"].data.dv_order.budget.budgetUnit || ""
                    //Segements
                    for (let i = 0; i < results["order"].data.dv_order.budget.budgetSegments.length; i++) {
                        let segment = results["order"].data.dv_order.budget.budgetSegments[i]
                        let sDate = segment.dateRange.startDate || ""
                        let eDate = segment.dateRange.endDate || ""

                        order.budget_segments[i] = {
                            budget_amount: (segment.budgetAmountMicros / Micros),
                            budget_description: segment.description,
                            start_date: DateFormat(sDate.year, sDate.month, sDate.day),
                            end_date: DateFormat(eDate.year, eDate.month, eDate.day),
                        }
                    }
                }
                if (results["order"].data.dv_order.pacing) {
                    order.pacing.period = results["order"].data.dv_order.pacing.pacingPeriod || ""
                    order.pacing.type = results["order"].data.dv_order.pacing.pacingType || ""
                    if (results["order"].data.dv_order.pacing.dailyMaxMicros != '') {
                        order.pacing.daily_max_type = '$' || ""
                        order.pacing.max_amount = (results['order'].data.dv_order.pacing.dailyMaxMicros / Micros).toString() || ""
                    } else if (results["order"].data.dv_order.pacing.dailyMaxImpressions != '') {
                        order.pacing.daily_max_type = 'imp' || ""
                        order.pacing.max_impressions = results['order'].data.dv_order.pacing.dailyMaxImpressions || ""
                    }
                }
                if (results['order'].data.dv_order.performanceGoal) {
                    order.performance_goal.type = results["order"].data.dv_order.performanceGoal.performanceGoalType || ""
                    if (results["order"].data.dv_order.performanceGoal.performanceGoalAmountMicros > 1) {
                        order.performance_goal.perf_amount = (results["order"].data.dv_order.performanceGoal.performanceGoalAmountMicros / Micros).toString() || ""
                        order.performance_goal.metric = "$" || ""
                    } else {
                        order.performance_goal.perf_amount = ((results["order"].data.dv_order.performanceGoal.performanceGoalPercentageMicros / Micros) * 100).toString() || ""
                        order.performance_goal.metric = "%" || ""
                    }
                }

                if (results["creatives"] && results["creatives"].data) {
                    for (let i = 0; i < results["creatives"].data.length; i++) {

                        const creative_label = `${results["creatives"].data[i].name} | ${results["creatives"].data[i].platform_id}`
                        GroupForm.fields[0][0].options.push({ label: creative_label, value: results["creatives"].data[i].platform_id.toString(), name: 'creatives', advertiser_id: results["creatives"].data[i].client_id.toString() })
                    }
                }
                if (results["order"].data.dv_targeting) {
                    console.log(results["order"])
                    SetTargeting(targeting, results["order"].data.dv_targeting)

                }
            }
        }
        // const contentOptions = BuildOptions(results["targeting"].data.content, 20)
        // const sensitiveOptions = BuildOptions(results["targeting"].data.sensitive, 19)
        let activeGroups = 0
        if (results["groups"] && results["groups"].data) {
            activeGroups = CountEntities(results["groups"].data)
        }

        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <><Tab.Container defaultActiveKey="first">
                <Row>

                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Crumbs data={results["crumbs"].data} platform="DV" type="order" />

                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                            <h4 className="me-2 h4">{results["order"].data.name}</h4>
                                            <span> - Order</span>
                                        </div>
                                    </div>
                                    <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="first" className="button">Dash</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="second" className="button">Settings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="third" className="button">Groups</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="fourth" className="button">Automizer</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="fifth" className="button">Targeting</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <Card.Header>
                                <div className="header-title">
                                    <h4 className="card-title">Snapshot</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <ul className="list-inline m-0 p-0">
                                    <li className="d-flex">
                                        <div className="new-icon me-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-collection-fill" viewBox="0 0 16 16"><path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"></path></svg>
                                        </div>
                                        <p className="news-detail mb-0">{activeGroups} - Active Groups</p>

                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <Card.Header>
                                <div className="header-title">
                                    <h4 className="card-title">Order Info</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="mb-1">Type: {order.insertion_order_type}</div>
                                <div className="mb-1">ID: {order.order_id}</div>
                                <div className="mb-1">Billable Outcome: {order.billable_outcome}</div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="12">
                        <Tab.Content className="profile-content">
                            <Tab.Pane eventKey="third" id="group">
                                <Card>
                                    <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Group List</h4>
                                        </div>
                                        <div>
                                            <ModalButton name="New Group" setModal={setModal2} />
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="px-0">
                                        {(results["groups"] && results["groups"].data) ? (

                                            <div className="table-responsive">
                                                <ReactTable item={results["groups"]} col={columnsGroup} page_size={20} row_size={20} />
                                            </div>
                                        ) : (<></>)}
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="first" id="dash">
                                <Row className='dateButtons_sub'>
                                    <Col lg={{ span: 6, offset: 6 }}>
                                    <DateButtons setDate={setDate} date={date} />
                                    </Col>
                                </Row>

                                    <Dash dash={results["dash"].data["dv"]} account={results["account"]} />
                            </Tab.Pane >
                            <Tab.Pane eventKey='fourth' id="group">
                                <Automizer data={results["order"]} cur={results["account"].data.currency} label="order" />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth" id="targeting">
                                <Row>
                                    {/* <button onClick={(() => dispatch({ type: ACTION.TG_COLOR }))}>Color</button> */}
                                    <Col lg="12">

                                        <InputSettings
                                            title='Add Targeting'
                                            mode='edit'
                                            form={TargetingForm}
                                            url={url}
                                            formId={"targeting"}
                                            model={[targeting, setTargeting]}
                                            parentModel={[order, setOrder]}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Alert variant={alert.variant}>{alert.value}</Alert>
                                </Row>
                            </Tab.Pane>


                            <Tab.Pane eventKey="second" id="settings">
                                <Row>
                                    <Col lg="12">
                                        <InputSettings
                                            title='Add New Order'
                                            mode='edit'
                                            form={OrderForm}
                                            url={url}
                                            formId={"orderform"}
                                            model={[order, setOrder]}
                                        />
                                    </Col>
                                </Row>
                            </Tab.Pane >
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
                <GroupModal group={group} setGroup={setGroup} form={GroupForm} formId='groupForm' modal={modal2} setModal={setModal2} baseUrl={REACT_APP_API_URL} />
            </>
        );
    }
    return <><div><span><Loader></Loader></span></div></>;
}
export default (Order)
