const status_prefix = 'ENTITY_STATUS_'

const general_info = [
    {
        label: 'Campaign ID',
        input: 'dropdown',
        name: 'campaign_id',
        options: [],
        size: '6',
        required: true,
        feedback: 'Campaign ID is required'
    },
    {
        label: 'Order Name',
        input: 'input-text',
        name: 'name',
        size: '6',
        required: true,
        feedback: 'Order name is required'
    },
    {
        label: 'Status',
        input: 'dropdown',
        name: 'status',
        options: [
            { name: 'status', label: 'Active', value: `${status_prefix}ACTIVE` },
            { name: 'status', label: 'Draft', value: `${status_prefix}DRAFT` },
            { name: 'status', label: 'Paused', value: `${status_prefix}PAUSED` }
        ],
        size: '6',
        required: true,
        feedback: 'Status is required'
    },
    {
        label: 'Order Type',
        input: 'dropdown',
        name: 'insertion_order_type',
        options: [
            { name: 'insertion_order_type', label: 'RTB', value: 'RTB' },
            { name: 'insertion_order_type', label: 'Over The Top', value: 'OVER_THE_TOP' }
        ],
        size: '6',
        required: true,
        feedback: 'Order type is required'
    }
]

const pacing_period_prefix = 'PACING_PERIOD_'
const pacing_type_prefix = 'PACING_TYPE_'
const pacing = [


    {
        label: 'Pacing Period',
        input: 'dropdown',
        name: 'pacing.period',
        options: [
            { name: 'pacing_period', label: 'Daily', value: `${pacing_period_prefix}DAILY` },
            { name: 'pacing_period', label: 'Entire Flight', value: `${pacing_period_prefix}FLIGHT` }
        ],
        size: '4',
        required: true,
        feedback: 'Pacing period is required'
    },
    {
        label: 'Pacing Type',
        input: 'dropdown',
        name: 'pacing.type',
        if: {
            condition: 'pacing.period',
            value: `${pacing_period_prefix}FLIGHT`,
            options: [
                { name: 'pacing.type', label: 'ASAP', value: `${pacing_type_prefix}ASAP` },
                { name: 'pacing.type', label: 'Ahead', value: `${pacing_type_prefix}AHEAD` },
                { name: 'pacing.type', label: 'Even', value: `${pacing_type_prefix}EVEN` }
            ],
        },
        else: {
            options: [
                { name: 'pacing.type', label: 'ASAP', value: `${pacing_type_prefix}ASAP` },
                { name: 'pacing.type', label: 'Even', value: `${pacing_type_prefix}EVEN` }
            ],
        },
        size: '4',
        required: true,
        feedback: 'Pacing type is required'
    },
    {
        label: 'Amount',
        name: 'pacing.amount',
        input: 'input-combo',
        size: "4",
        required: true,
        feedback: "Please enter a goal amount",
        options: ["$", "Impressions"],
        dependent: "pacing.period",
        condition: "PACING_PERIOD_DAILY",
        addOnName: "pacing.metric",
    },


]

const frequency = [
    {
        label: "Unlimited",
        input: "toggle",
        name: "frequency_cap.unlimited",
        size: "12"
    },
    {
        label: "Total Allowed Impressions",
        input: "input-number",
        name: "frequency_cap.max_impressions",
        size: "4",
        dependent: "frequency_cap.unlimited",
        condition: false,
    },
    {
        label: "Duration Count",
        input: "input-number",
        name: "frequency_cap.count",
        size: "4",
        dependent: "frequency_cap.unlimited",
        condition: false,
    },
    {
        label: 'Duration Unit',
        input: 'dropdown',
        options: [
            { label: "Lifetime", value: "TIME_UNIT_LIFETIME" },
            { label: "Months", value: "TIME_UNIT_MONTHS" },
            { label: "Weeks", value: "TIME_UNIT_WEEKS" },
            { label: "Days", value: "TIME_UNIT_DAYS" },
            { label: "Hours", value: "TIME_UNIT_HOURS" },
            { label: "Minutes", value: "TIME_UNIT_MINUTES" },
        ],
        name: 'frequency_cap.time_unit',
        dependent: "frequency_cap.unlimited",
        condition: false,
        size: "4",
    },
]

const performance_goal_info = [
    {
        label: "Goal Metric",
        input: 'radio',
        name: 'performance_goal.metric',
        options: [
            { name: 'performance_goal.metric', label: 'Amount ($)', value: '$' },
            { name: 'performance_goal.metric', label: 'Percentage (%)', value: '%' }
        ],
        size: '4',
        required: true,
        feedback: 'Performance goal metric is required'
    },
    {
        label: "Performance Goal Type",
        input: 'dropdown',
        dependent: 'performance_goal.metric',
        condition:"$",
        name: 'performance_goal.type',
        options: [
            { name: 'performance_goal_type', label: 'CPM', value: 'PERFORMANCE_GOAL_TYPE_CPM' },
            { name: 'performance_goal_type', label: 'CPC', value: 'PERFORMANCE_GOAL_TYPE_CPC' },
            { name: 'performance_goal_type', label: 'CPA', value: 'PERFORMANCE_GOAL_TYPE_CPA' },
            { name: 'performance_goal_type', label: 'CPIAVC', value: 'PERFORMANCE_GOAL_TYPE_CPIAVC' },
            { name: 'performance_goal_type', label: 'VCPM', value: 'PERFORMANCE_GOAL_TYPE_VCPM' }
        ],
        size: '4',
        required: true,
        feedback: 'Performance goal type is required'
    },
    {
        label: "Performance Goal Type",
        input: 'dropdown',
        name: 'performance_goal.type',
        dependent: 'performance_goal.metric',
        condition:"%",
        options: [
            { name: 'performance_goal_type', label: 'CTR', value: 'PERFORMANCE_GOAL_TYPE_CTR' },
            { name: 'performance_goal_type', label: 'Viewability', value: 'PERFORMANCE_GOAL_TYPE_VIEWABILITY' },
            { name: 'performance_goal_type', label: 'CCVR', value: 'PERFORMANCE_GOAL_TYPE_CLICK_CVR' },
            { name: 'performance_goal_type', label: 'ICVR', value: 'PERFORMANCE_GOAL_TYPE_IMPRESSION_CVR' },
            { name: 'performance_goal_type', label: 'VTR', value: 'PERFORMANCE_GOAL_TYPE_VTR' },
            { name: 'performance_goal_type', label: 'Audio Completion Rate', value: 'PERFORMANCE_GOAL_TYPE_AUDIO_COMPLETION_RATE' },
            { name: 'performance_goal_type', label: 'Video Completion Rate', value: 'PERFORMANCE_GOAL_TYPE_VIDEO_COMPLETION_RATE' }
        ],
        size: '4',
        required: true,
        feedback: 'Performance goal metric is required'
    },
    {
        label: "Amount",
        input: "input-number",
        name: 'performance_goal.perf_amount',
        dependent: 'performance_goal.metric',
        condition: "$",
        icon: "$",
        size: "4"
    },
    {
        label: "Amount",
        input: "input-number",
        name: 'performance_goal.perf_amount',
        dependent: 'performance_goal.metric',
        condition: "%",
        icon: "%",
        size: "4"
    }
    

]

const unitPrefix = 'INSERTION_ORDER_AUTOMATION_TYPE_'

const budget_info = [
    {
        label: 'Budget Unit',
        input: 'radio',
        name: 'budget.unit',
        options: [
            { name: 'budget_unit', label: 'Amount ($)', value: 'BUDGET_UNIT_CURRENCY' },
            { name: 'budget_unit', label: 'Impressions', value: 'BUDGET_UNIT_IMPRESSIONS' },
        ],
        size: '6',
        required: true,
        feedback: 'Budget unit is required'
    },
    {
        label: 'Automation Type',
        input: 'dropdown',
        name: 'budget.automation_type',
        options: [
            { name: 'budget_automation_type', label: 'Budget', value: `${unitPrefix}BUDGET` },
            { name: 'budget_automation_type', label: 'None', value: `${unitPrefix}NONE` },
            { name: 'budget_automation_type', label: 'Bid/Budget', value: `${unitPrefix}BID_BUDGET` }
        ],
        size: '6',
        required: true,
        feedback: 'Budget automation type is required'
    },
    {
        label: 'Budget(s)',
        input: 'segment',
        name: 'budget_segments',                
        size: '12',
        required: false,
        feedback: 'Budget(s) is required'
    },   
]

const bid_strat_goal_type_prefix = 'BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_'

const bidding_info = [
    {
        label: 'Strategy',
        input: 'dropdown',
        name: 'bidding_strategy',
        options: [          
            { label: 'Fixed Bid', value: 'Fixed', name: 'bidding_strategy' },
            { label: 'Auto Bid', value: 'Auto', name: 'bidding_strategy' }
        ],
        required: true,
        feedback: 'Bid strategy is required',
        size:"4"
    },
    {
        label: 'Performance Goal Type (Auto Bid)',
        input: 'dropdown',
        name: 'auto_bid_performance_goal_type',
        options: [
            { label: 'CPA', value: `${bid_strat_goal_type_prefix}CPA`, name: 'auto_bid_performance_goal_type' },
            { label: 'CPC', value: `${bid_strat_goal_type_prefix}CPC`, name: 'auto_bid_performance_goal_type' },
            { label: 'vCPM', value: `${bid_strat_goal_type_prefix}VIEWABLE_CPM`, name: 'auto_bid_performance_goal_type' },
            { label: 'Custom', value: `${bid_strat_goal_type_prefix}CUSTOM_ALGO`, name: 'auto_bid_performance_goal_type' },
            { label: 'CIVA', value: `${bid_strat_goal_type_prefix}CIVA`, name: 'auto_bid_performance_goal_type' },
            { label: 'IVO Ten', value: `${bid_strat_goal_type_prefix}IVO_TEN`, name: 'auto_bid_performance_goal_type' },
            { label: 'AV Viewed', value: `${bid_strat_goal_type_prefix}AV_VIEWED`, name: 'auto_bid_performance_goal_type' }
        ],
        dependent: 'bidding_strategy',
        condition: 'Auto',
        size:"4"
    },
    {
        label: "Performance Goal Amount",
        input: "input-number",
        dependent:"bidding_strategy",
        condition:"Auto",
        icon: "$",
        name: "auto_bid_performance_goal_amount",
        size:"6"        
    },
    {
        label: "Performance Max Bid",
        input: "input-number",
        dependent:"bidding_strategy",
        condition:"Auto",
        icon: "$",
        name: "auto_bid_max_bid",        
        size:"6"

    }
]
const partner_costs = [
    {
        label: 'Partner Costs',
        input: 'segment',
        name: 'partner_costs',                
        size: '12',
        required: false,
        feedback: 'Partner fee is required'
    },
]
const OrderForm = {
    titles: ["General", "Pacing", "Frequency Cap", "Goals", "Budget", "Bidding", "Partner Costs"],
    fields: [
        general_info,
        pacing,
        frequency,
        performance_goal_info,
        budget_info,
        bidding_info,       
        partner_costs 
    ]
}

export { OrderForm }